import Data from '../Class';

export default class ProductData extends Data {
  properties() {
    return {
      Description: { type: 'string' },
      Hash: { type: 'string' },
      Live: { type: 'boolean', defaultValue: false },
      Symbol: { type: 'string' },
      Markup: { type: 'string' },
    };
  }

  computed() {
    return {
      Label({ Description }) {
        return Description?.split('/')[0] || null;
      },
    };
  }
}
