import { useState, useEffect } from 'react';
import ListItem from './ListItem';
import NAV_LINKS from './NAV_LINKS';
import { Icon } from '@digitalmint/web-components';
import { Color } from 'src/utils';
import AuthService from 'src/services/Auth';
import classnames from 'classnames';

export default function () {
  const [expanded, setExpanded] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleResize = () => setExpanded(false);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    setIsAuthenticated(AuthService.isAuthenticated);
  }, [AuthService.isAuthenticated]);

  return (
    <>
      <button
        aria-expanded={expanded}
        aria-label="Open Main Menu"
        className="site-menu-open"
        onClick={() => setExpanded(true)}
      >
        <Icon icon="menu" color={Color.blue[500]} height="30" width="30" />
      </button>

      <nav
        role="navigation"
        aria-label="Main Menu"
        className={classnames('site-menu', { expanded })}
      >
        <div className="site-menu-nav-controls">
          <h4 className="site-menu-nav-controls-title">Menu</h4>
          <button
            aria-expanded={expanded}
            aria-label="Close Main Menu"
            className="site-menu-close"
            onClick={() => setExpanded(false)}
          >
            <Icon icon="close" color={Color.blue[500]} height="24" width="24" />
          </button>
        </div>
        <ul className="site-menu-list">
          {NAV_LINKS.map(({ auth, icon, links, title }, index) => {
            if (auth) {
              if (isAuthenticated) {
                return (
                  <ListItem
                    onLogout={async () => {
                      await AuthService.logout();

                      setIsAuthenticated(false);
                    }}
                    onClose={() => setExpanded(false)}
                    expanded={expanded}
                    icon={icon}
                    key={index}
                    links={links}
                    title={title}
                  />
                );
              } else {
                return false;
              }
            } else {
              return (
                <ListItem
                  onClose={() => setExpanded(false)}
                  icon={icon}
                  links={links}
                  title={title}
                  key={index}
                />
              );
            }
          })}
        </ul>
      </nav>
    </>
  );
}
