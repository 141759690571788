//   // transactions are started with the customer entering a phone number
//   // 100 series: pending customer information or approval by employee
//   TransactionStatePendingIDScan    TransactionState = 100
//   TransactionStatePendingIDInfo    TransactionState = 101 // reserved, additional ID info
//   TransactionStatePendingConfirmID TransactionState = 130 // teller confirms ID
//   TransactionStatePendingSSN       TransactionState = 150
//   TransactionStatePendingRiskCheck TransactionState = 190 // reserved
//
//   // 200 series: receive assets from customer
//   TransactionStatePendingWithdrawalWalletAddress TransactionState = 200
//   TransactionStateReceivingCash                  TransactionState = 210
//   TransactionStatePendingDepositWalletAddress    TransactionState = 250 // reserved
//   TransactionStateReceivingCrypto                TransactionState = 260 // reserved
//
//   // 300 series: dispense assets/clearing
//   TransactionStateExecuted            TransactionState = 300
//   TransactionStatePendingBatch        TransactionState = 305 // lock updates here.
//   TransactionStateSettlementInFlight  TransactionState = 307 // executing
//   TransactionStatePendingConfirmation TransactionState = 310
//   TransactionStateDispensingCash      TransactionState = 350 // reserved
//
//   // 400 series: terminal states/finished
//   TransactionStateTerminalThreshold                  = 400
//   TransactionStateSettled           TransactionState = 410
//   TransactionStateCanceled          TransactionState = 420
//
//   // 500 series: error states
//   TransactionStateDisrupted        TransactionState = 500
//   TransactionStateBannedCustomer   TransactionState = 510 // reserved
//   TransactionStateRiskCheckFailed  TransactionState = 520 // reserved
//   TransactionStateFrozen           TransactionState = 560
//   TransactionStateAlreadyCancelled TransactionState = 570

export default {
  PENDING_BATCH: {
    code: 305,
    value: 'Pending Batch',
  },
  PENDING_CONFIRMATION: {
    code: 310,
    value: 'Pending Confirmation',
  },
  SETTLED: { code: 410, value: 'Settled' },
  CANCELLED: { code: 420, value: 'Cancelled' },
  FROZEN: {
    code: 560,
    value: 'Frozen',
  },
};
