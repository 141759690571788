import { apiRouteExtension } from 'src/services/Api';
import { IDVerificationView } from 'src/views';
import { ENROLLMENT_TYPE } from 'src/types';

export default apiRouteExtension({
  path: '/enroll/id-verification',
  element: <IDVerificationView />,
  loader: async ({ request, fetch, redirect }) => {
    const url = new URL(request.url);
    const EnrollmentType = url.searchParams.get('enrollment_type');

    if (EnrollmentType) {
      return await fetch(
        {
          method: 'get',
          url: '/enroll',
          params: {
            enrollment_type: EnrollmentType,
          },
        },
        ({ data }) => {
          if (!data?.LinkToken) {
            return redirect('/enroll/error');
          } else {
            return { EnrollmentType, LinkToken: data.LinkToken };
          }
        }
      );
    } else {
      return redirect('/enroll/select-enrollment');
    }
  },
  action: async ({ request, redirect, fetch }) => {
    const formData = await request.formData();
    const IdentityVerificationID = formData.get('IdentityVerificationID');
    const EnrollmentType = formData.get('EnrollmentType');
    const SessionStatus = formData.get('SessionStatus');

    if (IdentityVerificationID && SessionStatus) {
      // Its only worth posting if we have the
      // identity verification ID. Bravo polls
      // for ID verification anyway, posting it
      // back here is just a nice to have.
      return await fetch(
        {
          method: 'post',
          url: '/enroll/register',
          body: {
            IdentityVerificationID,
          },
        },
        ({ error }) => {
          if (error) {
            return redirect('/enroll/error');
          } else if (EnrollmentType === ENROLLMENT_TYPE.DEBIT) {
            // TODO: if debit is available, we should capture
            // the status to either appropriately route after submitting
            // the wallet or update the status pages to include wallet as
            // an extra step for debit enrollments. As of 12/14/2023, debit
            // is not yet live and entering a wallet will not yet be an option.
            return redirect('/enroll/add-wallet');
          } else {
            return redirect(`/enroll/${SessionStatus}`);
          }
        }
      );
    } else if (SessionStatus) {
      // If a status exists when an identity verification
      // does not, then just redirect appropriately. Bravo
      // will pick up the status later in a polling event.
      return redirect(`/enroll/${SessionStatus}`);
    } else {
      // If no status is returned (or status is 'error') and we haven't
      // received back an identity verification id,
      // we can only assume an error occured.
      return redirect('/enroll/error');
    }
  },
});
