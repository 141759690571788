import { useState } from 'react';
import { useActionData, useNavigation } from 'react-router-dom';
import { Button, SecureTextInput } from '@digitalmint/web-components';
import WorkflowLayout, { WorkflowLayoutProps } from 'src/layouts/Workflow';

export default function (props: WorkflowLayoutProps) {
  const [isDisabled, setIsDisabled] = useState(true);
  const errors: any = useActionData();
  const isLoading = useNavigation().state === 'submitting';

  return (
    <WorkflowLayout
      subtitle={props.subtitle}
      title="Create your 4-6 digit PIN"
      method="post"
      errors={errors}
      icon="lock2"
      controls={
        <Button
          disabled={isDisabled}
          isLoading={isLoading}
          submit={true}
          text="Continue"
          testID="submit"
        />
      }
    >
      <SecureTextInput
        className="self-center mb-20"
        label="PIN"
        name="PIN"
        required={true}
        maxLength={6}
        testID="PIN"
        type="number"
        style={{ appearance: 'textfield' }}
        error={!!errors?.PIN}
        errorMessage={errors?.PIN}
      />

      <SecureTextInput
        className="self-center mb-20"
        label="Confirm PIN"
        name="ConfirmPIN"
        required={true}
        maxLength={6}
        testID="ConfirmPIN"
        type="number"
        style={{ appearance: 'textfield' }}
        onChange={({ target }) => {
          setIsDisabled(target.value?.length < 1);
        }}
        error={!!errors?.ConfirmPIN}
        errorMessage={errors?.ConfirmPIN}
      />
    </WorkflowLayout>
  );
}
