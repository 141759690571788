import { Link } from 'react-router-dom';
import { WorkflowLayout } from 'src/layouts';

export default function () {
  return (
    <WorkflowLayout
      title="Your PIN has been successfully updated!"
      icon="check"
      controls={
        <Link className="dm-button primary" to="/profile">
          Go Home
        </Link>
      }
    />
  );
}
