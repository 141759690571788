import { useState, useMemo } from 'react';
import { useLoaderData } from 'react-router-dom';
import { Radiogroup, Button } from '@digitalmint/web-components';
import WorkflowLayout, { WorkflowLayoutProps } from 'src/layouts/Workflow';

export default function (props: WorkflowLayoutProps) {
  const [isDisabled, setIsDisabled] = useState(true);
  const [description, setDescription] = useState('');
  const { EnrollmentTypes }: any = useLoaderData();

  const radios = EnrollmentTypes.map(({ Type, Description }) => {
    return { label: Description, value: Type };
  });

  const size = useMemo(() => EnrollmentTypes?.length, [EnrollmentTypes]);

  return (
    <WorkflowLayout
      subtitle={props.subtitle}
      title="Choose your purchase method"
      icon="bitcoin"
      method="post"
      controls={
        <Button
          submit={true}
          text="Continue"
          disabled={isDisabled}
          testID="submit"
        />
      }
    >
      <>
        <Radiogroup
          label="enrollment"
          name="EnrollmentType"
          className="button-radiogroup"
          radios={radios}
          required={true}
          orientation="vertical"
          testID="enrollmentOptions"
          onChange={value => {
            setIsDisabled(!value);

            const { Description } = EnrollmentTypes.find(({ Type }) => {
              return Type === value;
            });

            setDescription(Description);
          }}
        />

        <input type="hidden" name="Description" value={description} />
        <input type="hidden" name="size" value={size} />
      </>
    </WorkflowLayout>
  );
}
