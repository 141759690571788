import { NoFormLayout } from 'src/layouts';
import { CONTACT } from 'src/types';
import { formatPhoneNumber } from '@digitalmint/utilities/formatters';
import { Icon } from '@digitalmint/web-components';
import { Color } from 'src/utils';

export default function () {
  return (
    <NoFormLayout title="DigitalMint Customer Support">
      <div className="mb-48">
        <p className="text-center text-nickel-500 mb-12">
          Our Customer Support team is here to help protect you from Bitcoin
          scams and fraud. We're available to assist you via call, text, and
          email.
        </p>

        <p className="text-center text-nickel-500">
          Hours: <span dangerouslySetInnerHTML={{ __html: CONTACT.HOURS }} />{' '}
        </p>
        <p className="text-center text-nickel-500">
          Phone:{' '}
          <a
            className="font-bold border-b-0 hover:border-b-0"
            href={`tel:${CONTACT.PHONE}`}
          >
            {formatPhoneNumber(CONTACT.PHONE)}
          </a>
        </p>
        <p className="text-center text-nickel-500 mb-12">
          Email:{' '}
          <a
            className="font-bold border-b-0 hover:border-b-0"
            href={`mailto:${CONTACT.EMAIL}`}
          >
            {CONTACT.EMAIL}
          </a>
        </p>
      </div>

      <p className=" text-blue-600 text-md">How to reach us</p>
      <p className="mb-12 text-nickel-500">
        There are four ways you can reach out. Pick the option that best meets
        your needs. We love hearing from our customers.
      </p>

      <div className="flex justify-between my-32 items-end w-full">
        <div className="flex flex-col content-center flex-1 m-12">
          <a
            className="border-b-0 hover:border-b-0 no-wrap block mx-auto mb-8"
            href={`tel:${CONTACT.PHONE}`}
          >
            <Icon color={Color.green[500]} icon="phone" />
          </a>
          <a
            className="border-b-0 hover:border-b-0 no-wrap"
            href={`tel:${CONTACT.PHONE}`}
          >
            Call Us
          </a>
        </div>
        <div className="flex flex-col content-center flex-1 m-12">
          <a
            className="border-b-0 hover:border-b-0 no-wrap block mx-auto mb-8"
            href={`mailto:${CONTACT.EMAIL}`}
          >
            <Icon color={Color.green[500]} icon="bill" />
          </a>
          <a
            className="border-b-0 hover:border-b-0 no-wrap"
            href={`mailto:${CONTACT.EMAIL}`}
          >
            Submit An Issue
          </a>
        </div>
      </div>

      <div className="flex flex-row items-end w-full">
        <div className="flex flex-col content-center flex-1 m-12">
          <a
            className="border-b-0 hover:border-b-0 no-wrap block mx-auto mb-8"
            href="https://www.digitalmint.io/become-a-partner/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon color={Color.green[500]} icon="currencyExchange" />
          </a>
          <a
            className="border-b-0 hover:border-b-0 no-wrap"
            href="https://www.digitalmint.io/become-a-partner/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Partner With Us
          </a>
        </div>
        <div className="flex flex-col content-center flex-1 m-12">
          <a
            className="border-b-0 hover:border-b-0 no-wrap block mx-auto mb-8"
            href="https://www.digitalmint.io/careers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon color={Color.green[500]} icon="profile" />
          </a>
          <a
            className="border-b-0 hover:border-b-0 no-wrap"
            href="https://www.digitalmint.io/careers/"
            target="_blank"
            rel="noopener noreferrer"
          >
            We're Hiring
          </a>
        </div>
      </div>
    </NoFormLayout>
  );
}
