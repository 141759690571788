import { useState } from 'react';
import { useActionData, useLoaderData, useNavigation } from 'react-router-dom';
import { Button, TextInput } from '@digitalmint/web-components';
import { WorkflowLayout } from 'src/layouts';
import WarnIcon from 'src/services/Notifications/components/icons/warn.svg';
import { WorkflowLayoutProps } from 'src/layouts/Workflow';

export default function (props: WorkflowLayoutProps) {
  const [isDisabled, setIsDisabled] = useState(true);
  const errors: any = useActionData();
  const isLoading = useNavigation().state === 'submitting';
  const { Country, State }: any = useLoaderData();

  return (
    <WorkflowLayout
      subtitle={props.subtitle}
      title="Signup to be alerted when we are available in your region"
      icon="laptop"
      errors={errors}
      controls={
        <Button
          disabled={isDisabled}
          isLoading={isLoading}
          submit={true}
          text="Continue"
          testID="submit"
        />
      }
    >
      <div
        className="notification-item warn mb-20"
        role="alert"
        aria-live="assertive"
        data-testid="country-alert"
      >
        <img className="notification-item-icon" src={WarnIcon} alt="Warning" />
        <div className="notification-item-content">
          <p>
            Due to local regulations, buying or selling crypto currencies with
            DigitalMint is currently not available in your region.
          </p>
        </div>
      </div>
      <TextInput
        className="self-center mb-20"
        label="E-mail Address"
        name="Email"
        required={true}
        testID="Email"
        type="email"
        style={{ appearance: 'textfield' }}
        onChange={({ target }) => {
          setIsDisabled(target.value?.length < 1);
        }}
        error={!!errors?.Email}
        errorMessage={errors?.Email}
      />
      <input type="hidden" name="Country" value={Country || ''} />
      <input type="hidden" name="State" value={State || ''} />
    </WorkflowLayout>
  );
}
