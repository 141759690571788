import {
  useActionData,
  useLoaderData,
  useNavigation,
  Link,
} from 'react-router-dom';
import { WorkflowLayout } from 'src/layouts';
import { Button, WalletAddress } from '@digitalmint/web-components';
import { WorkflowLayoutProps } from 'src/layouts/Workflow';

export default function (props: WorkflowLayoutProps) {
  const errors: any = useActionData();
  const isLoading = useNavigation().state === 'submitting';
  const { DefaultWallet }: any = useLoaderData();

  return (
    <WorkflowLayout
      subtitle={props.subtitle}
      title="Confirm Default Wallet"
      errors={errors}
      controls={
        <>
          <Button isLoading={isLoading} submit={true} text="Confirm" />
          <Link
            to="/enroll/default-wallet"
            data-testid="cancel"
            className="dm-button secondary mt-12"
          >
            Change Wallet
          </Link>
        </>
      }
    >
      <p className="text-blue-600 text-sm text-normal mb-[60px]">
        Debit purchases require having a wallet address saved in advance.
      </p>

      <div className="variable-width">
        <p className="font-bold text-gray-600 mb-12 text-left w-full">
          Wallet Address
        </p>
        <WalletAddress className="text-center" address={DefaultWallet} />
        <input type="hidden" name="DefaultWallet" value={DefaultWallet} />
      </div>
    </WorkflowLayout>
  );
}
