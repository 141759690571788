import ApiService from '../Api';
import createProxy from '../Proxy';
import ProfileData from '../Data/source/Profile';

export const struct = Object.freeze({
  Profile: {
    default: undefined,
    type: 'object',
  },
  isAuthenticated: {
    default: false,
    type: 'boolean',
  },
});

class Auth {
  proxy: any = createProxy('AuthService.proxy', struct);

  get Profile() {
    return this.proxy.Profile;
  }

  set Profile(data) {
    const Profile: any = new ProfileData();

    Profile.set(data);

    this.proxy.Profile = Profile.attributes;
  }

  get isAuthenticated() {
    return this.proxy.isAuthenticated;
  }

  set isAuthenticated(isAuthenticated) {
    this.proxy.isAuthenticated = isAuthenticated;
  }

  clear() {
    this.Profile = undefined;
    this.isAuthenticated = false;
  }

  async check() {
    if (this.isAuthenticated && this.Profile.Hash != undefined) {
      return true;
    } else {
      const { data, success } = await ApiService.fetch({
        method: 'get',
        url: '/profile',
      });

      this.isAuthenticated = success;

      if (data && success) {
        this.Profile = data;
      }

      return this.isAuthenticated;
    }
  }

  async login({ PhoneNumber, Password }) {
    const { data, error, errorMessage, success } = await ApiService.fetch({
      method: 'post',
      url: '/signin',
      body: {
        PhoneNumber,
        Password,
      },
    });

    this.isAuthenticated = success;

    if (success) {
      this.Profile = data;
    }

    return {
      error,
      errorMessage,
      success,
    };
  }

  async logout() {
    const { error, errorMessage, success } = await ApiService.fetch({
      method: 'post',
      url: '/logout',
    });

    if (success) this.clear();

    // window?.location?.reload();

    return {
      error,
      errorMessage,
      success,
    };
  }
}

export default new Auth();
