import { Icon } from '@digitalmint/web-components';
import { Color } from 'src/utils';
import PropTypes from 'prop-types';

export default function NoFormLayout({
  children,
  icon,
  testID = 'xNoForm',
  title,
}) {
  return (
    <>
      {icon && (
        <Icon
          className="self-center mb-20"
          color={Color.green[500]}
          icon={icon}
          width="42"
          height="42"
        />
      )}
      <div
        className="flex flex-col m-0 grow items-center justify-center w-full"
        data-testid={testID}
      >
        <header className="text-center">
          <h1 className="heading-3 mb-20">{title}</h1>
        </header>
        <div className="w-full mx-0 my-auto text-center flex flex-col items-center justify-center">
          {children}
        </div>
      </div>
    </>
  );
}

NoFormLayout.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.string,
  testID: PropTypes.string,
  title: PropTypes.string.isRequired,
};
