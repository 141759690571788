import { WorkflowLayout } from 'src/layouts';

export default function () {
  return (
    <WorkflowLayout title="Success!" icon="check">
      <p className="text-md text-normal">
        Thank you for your interest. We'll be in touch soon!
      </p>
    </WorkflowLayout>
  );
}
