import Data from '../Class';
import UnderlyingBaseData from './UnderlyingBase';

export default class TransactionData extends Data {
  properties() {
    return {
      Collected: { type: 'number' }, // amount in cash
      BaseQuantity: { type: 'number' }, // amount in coin
      Hash: { type: 'string' },
      Side: { type: 'string' }, // purchase type - buy or sell
      State: { type: 'number' }, // tx state - pending, cancelled, etc
      StateDesc: { type: 'string' },
      UpdatedAt: { type: 'string' },
      CreatedAt: { type: 'string' },
    };
  }

  hasOne() {
    return {
      Base: UnderlyingBaseData,
    };
  }
}
