import { useState } from 'react';
import { Link, useActionData, useNavigation } from 'react-router-dom';
import { Button, SecureTextInput } from '@digitalmint/web-components';
import { WorkflowLayout } from 'src/layouts';
import { useApi } from 'src/services/Api';
import { useNotifications } from 'src/services/Notifications';

export default function () {
  const [isDisabled, setIsDisabled] = useState(true);
  const errors: any = useActionData();
  const isLoading = useNavigation().state === 'submitting';
  const notify = useNotifications();

  const [handleResendCode] = useApi({
    method: 'post',
    url: 'email/verify/resend',
    onError(message) {
      notify.alert(message);
    },
    onSuccess() {
      notify.success('A confirmation code has been sent to you by email.');
    },
  });

  return (
    <WorkflowLayout
      title="Confirmation Code"
      subtitle="Enter the code sent to your email"
      icon="lock"
      errors={errors}
      controls={
        <>
          <Button
            className="mb-20"
            disabled={isDisabled}
            isLoading={isLoading}
            submit={true}
            text="Continue"
            testID="submit"
          />

          <Link to="/profile" className="dm-button secondary">
            Cancel
          </Link>
        </>
      }
    >
      <SecureTextInput
        className="self-center mb-20"
        label="Confirmation Code"
        name="ConfirmCode"
        required={true}
        maxLength={6}
        testID="ConfirmCode"
        type="number"
        style={{ appearance: 'textfield' }}
        onChange={({ target }) => {
          setIsDisabled(target.value?.length < 1);
        }}
        error={!!errors?.ConfirmCode}
        errorMessage={errors?.ConfirmCode}
      />

      <Button
        onClick={async () => await handleResendCode()}
        type="link"
        testID="resend"
        text="Resend Confirmation Code"
      />
    </WorkflowLayout>
  );
}
