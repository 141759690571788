import { useState } from 'react';
import { useActionData, useNavigation } from 'react-router-dom';
import { Button, SecureTextInput } from '@digitalmint/web-components';
import WorkflowLayout from 'src/layouts/Workflow';
import PropTypes from 'prop-types';

export default function CreatePassword({
  subtitle,
  title = 'Create your password',
}) {
  const [isDisabled, setIsDisabled] = useState(true);
  const errors: any = useActionData();
  const isLoading = useNavigation().state === 'submitting';

  return (
    <WorkflowLayout
      subtitle={subtitle}
      title={title}
      method="post"
      errors={errors}
      icon="lock2"
      controls={
        <Button
          disabled={isDisabled}
          isLoading={isLoading}
          submit={true}
          text="Continue"
          testID="submit"
        />
      }
    >
      <SecureTextInput
        className="self-center mb-20"
        label="Password"
        name="Password"
        required={true}
        minLength={8}
        testID="Password"
        style={{ appearance: 'textfield' }}
        error={!!errors?.Password}
        errorMessage={errors?.Password}
      />

      <SecureTextInput
        className="self-center mb-20"
        label="Confirm Password"
        name="ConfirmPassword"
        required={true}
        testID="ConfirmPassword"
        style={{ appearance: 'textfield' }}
        onChange={({ target }) => {
          setIsDisabled(target.value?.length < 1);
        }}
        error={!!errors?.ConfirmPassword}
        errorMessage={errors?.ConfirmPassword}
      />
    </WorkflowLayout>
  );
}

CreatePassword.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
};
