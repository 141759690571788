import Data from '../Class';

export default class AddressData extends Data {
  properties() {
    return {
      Hash: { type: 'string' },
      City: { type: 'string' },
      Country: { type: 'string' },
      StateCode: { type: 'string' },
      Street2: { type: 'string' },
      Street: { type: 'string' },
      ZIP: { type: 'string' },
    };
  }
}
