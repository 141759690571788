import { useNavigate } from 'react-router-dom';
import { Button } from '@digitalmint/web-components';
import { WorkflowLayout } from 'src/layouts';

export default function () {
  const navigate = useNavigate();
  return (
    <WorkflowLayout
      title="Your email has been successfully updated!"
      icon="check"
      controls={
        <Button
          text="Go Home"
          testID="profile"
          onClick={() => navigate('/profile')}
        />
      }
    ></WorkflowLayout>
  );
}
