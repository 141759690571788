import { Link } from 'react-router-dom';
import { Crypticon } from '@digitalmint/web-components';
import WorkflowLayout from 'src/layouts/Workflow';
import { WalletPopup } from 'src/components/popups';
import wallet from '../images/green-wallet.png';

export default function () {
  return (
    <WorkflowLayout
      title="Add Default Wallet Address"
      controls={
        <>
          <Link
            className="dm-button secondary flex flex-row"
            data-testid="add-wallet"
            to="/enroll/default-wallet"
          >
            Add Wallet
            <Crypticon symbol="btc" height="20" />
          </Link>
          <Link
            className="text-sm font-bold mt-24"
            data-testid="skip"
            to="/profile"
          >
            Skip
          </Link>
        </>
      }
    >
      <p className="text-blue-600 text-sm text-normal">
        Debit purchases require having a wallet address saved in advance.
      </p>

      <WalletPopup />

      <div className="mt-32 mb-32">
        <img className="self-center" src={wallet} alt="green wallet" />
      </div>
    </WorkflowLayout>
  );
}
