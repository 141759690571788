import Data from '../Class';

export default class WalletVerificationData extends Data {
  properties() {
    return {
      Flagged: { type: 'boolean', defaultValue: false },
      Symbol: { type: 'string' },
      Valid: { type: 'boolean', defaultValue: false },
      WalletAddress: { type: 'string' },
    };
  }

  computed() {
    return {
      isVerified(properties) {
        return !properties?.Flagged && properties?.Valid;
      },
    };
  }
}
