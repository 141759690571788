import { apiRouteExtension } from 'src/services/Api';
import { ConfirmWalletView } from 'src/views';
import AnalyticsService from 'src/services/Analytics';
import { ProfilePath } from 'src/routes/paths';

export default apiRouteExtension({
  path: '/enroll/default-wallet-confirm',
  element: <ConfirmWalletView />,
  loader: async ({ request, redirect }) => {
    const url = new URL(request.url);
    const DefaultWallet = url.searchParams.get('default_wallet');

    if (!DefaultWallet) {
      return redirect('/enroll/default-wallet');
    } else {
      return { DefaultWallet };
    }
  },
  action: async ({ request, redirect, fetch }) => {
    const formData = await request.formData();
    const DefaultWallet = formData.get('DefaultWallet');

    const errors: any = {};

    return await fetch(
      {
        method: 'POST',
        url: '/account',
        body: {
          DefaultWallet,
          ProductSymbol: 'BTCUSD',
        },
      },
      ({ error, errorMessage }) => {
        if (error) {
          errors.submit = errorMessage;
          AnalyticsService.log('confirm_wallet_error');

          return errors;
        } else {
          AnalyticsService.log('tpidv_set_default_wallet');
          return redirect(ProfilePath);
        }
      }
    );
  },
});
