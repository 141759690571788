import { apiRouteExtension } from 'src/services/Api';
import { CreatePasswordView } from 'src/views';

export default apiRouteExtension({
  path: '/enroll/create-password',
  element: <CreatePasswordView />,
  action: async ({ request, redirect, fetch }) => {
    const formData = await request.formData();
    const Password = formData.get('Password');
    const ConfirmPassword = formData.get('ConfirmPassword');
    const errors: any = {};

    if (!Password) {
      errors.Password = 'Password is required.';

      return errors;
    }

    if (!ConfirmPassword) {
      errors.ConfirmPassword = 'Confirm Password is required.';

      return errors;
    }

    if (Password !== ConfirmPassword) {
      errors.ConfirmPassword = 'Confirm Password does not match.';

      return errors;
    }

    return await fetch(
      {
        method: 'post',
        url: '/account',
        body: { Password },
      },
      ({ error, errorMessage }) => {
        if (error) {
          errors.submit = errorMessage;

          return errors;
        } else {
          return redirect('/enroll/select-enrollment');
        }
      }
    );
  },
});
