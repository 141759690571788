import { useState } from 'react';
import NotificationsContext from './Context';
import { NOTIFICATION, PRIORITY } from './types';
import { uid } from 'uid';
import PropTypes from 'prop-types';

export default function NotificationsProvider({ children, list = [] }: any) {
  const [notifications, setNotifications] = useState(list);

  const alert = message => {
    notify(NOTIFICATION.ALERT, message);
  };

  const info = message => {
    notify(NOTIFICATION.INFO, message);
  };

  const success = message => {
    notify(NOTIFICATION.SUCCESS, message);
  };

  const warn = message => {
    notify(NOTIFICATION.WARN, message);
  };

  const notify = (type, message) => {
    let body = message;

    // accept strings and return them as expected
    // with single paragraph tags.
    if (typeof body === 'string') {
      body = <p>{message}</p>;
    }

    const notification = {
      body,
      id: uid(),
      priority: PRIORITY[type],
      type,
    };

    setNotifications(
      [...notifications, notification].sort((a, b) => {
        if (a.priority < b.priority) {
          return -1;
        } else if (a.priority > b.priority) {
          return 1;
        } else {
          return 0;
        }
      })
    );
  };

  const onDismiss = id => {
    setNotifications(notifications.filter(item => item.id !== id));
  };

  return (
    <NotificationsContext.Provider
      value={{ alert, info, success, warn, notifications, onDismiss }}
    >
      {children}
    </NotificationsContext.Provider>
  );
}

NotificationsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  list: PropTypes.array,
};
