import { truncate } from 'lodash';

export default function (str, options = { start: 4, end: 4 }) {
  if (typeof str === 'number') {
    str = str.toString();
  }

  if (str && str.length > 10) {
    // Length for truncate accounts for ellipsis ("...")
    return (
      truncate(str, { length: (options.start || 4) + 3 }) +
      str.slice(str.length - (options.end || 4))
    );
  }
  return str;
}
