import { useMemo } from 'react';
import { Link, useLoaderData } from 'react-router-dom';
import WorkflowLayout, { WorkflowLayoutProps } from 'src/layouts/Workflow';
import { Icon } from '@digitalmint/web-components';
import { Color } from 'src/utils';

export default function (props: WorkflowLayoutProps) {
  const { EnrollmentType, Description, size }: any = useLoaderData();

  const path = useMemo(
    () =>
      encodeURI(`/enroll/id-verification?enrollment_type=${EnrollmentType}`),
    [EnrollmentType]
  );

  const showChangeLink = useMemo(() => parseInt(size) > 1, [size]);

  return (
    <WorkflowLayout
      subtitle={props.subtitle}
      title="Here's what you'll need to create an account quickly and easily"
      icon="bitcoin"
      method="post"
      controls={
        <>
          <Link to={path} className="dm-button">
            Continue
          </Link>

          {showChangeLink && (
            <Link
              to="/enroll/select-enrollment"
              className="dm-button secondary mt-12"
            >
              Change Purchase Method
            </Link>
          )}
        </>
      }
    >
      <ul className="text-left">
        <li className="flex items-start mb-12">
          <Icon
            icon="check"
            color={Color.green[500]}
            height="24"
            width="24"
            className="mr-8 inlin-block"
          />
          Submit a photo of a Government Issued ID
        </li>
        <li className="flex items-start mb-12">
          <Icon
            icon="check"
            color={Color.green[500]}
            height="24"
            width="24"
            className="mr-8 inline-block"
          />
          Additional personal information
        </li>
      </ul>

      <h4 className="text-gray-600">
        <p className="font-bold text-xs mt-12 mb-0">You are enrolling with</p>
        <p className="flex items-center justify-center">
          <Icon
            className="mr-4"
            icon="bitcoin"
            color={Color.green[500]}
            height="20"
            width="20"
          />{' '}
          {Description}
        </p>
      </h4>
    </WorkflowLayout>
  );
}
