import { Api } from '@digitalmint/api-service';
import AuthService from '../Auth';

const config = {
  baseUrl: import.meta.env.VITE_API_URL,
  namespace: import.meta.env.VITE_API_VERSION,
};

export type ApiMetaData = {
  code?: number
}

export type ApiResponseLibrary = {
   data: any
   error: any
   errorMessage: string
   success: boolean
   status: number
}

export type ApiResponse = ApiResponseLibrary & {
   metadata: ApiMetaData
};

class ApiService {
  api = new Api(config);

  async fetch(options = {}, callback?: (params:ApiResponse) => ApiResponse): Promise<ApiResponse> {
    const responseLibrary: ApiResponseLibrary = await this.api.fetch(options);

    if (responseLibrary.status === 401) {
      AuthService.clear();
    }

    const code = responseLibrary.data?.Code;

    const response = {
      ...responseLibrary,
      metadata: {
        code
      },
    };

    if (callback) {
      return callback(response);
    } else {
      return response;
    }
  }
}

export default new ApiService();
