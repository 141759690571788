import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, Analytics as FireAnalytics } from 'firebase/analytics';

const config = {
  authDomain: 'digitalmint-app-analytics.firebaseapp.com',
  projectId: 'digitalmint-app-analytics',
  storageBucket: 'digitalmint-app-analytics.appspot.com',
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: import.meta.env.VITE_FIREBASE_SENDER_ID,
};

class Analytics {
  analytics: FireAnalytics | null = null;

  constructor() {
    if (
      import.meta.env.PROD &&
      config.apiKey &&
      config.appId &&
      config.measurementId &&
      config.messagingSenderId
    ) {
      initializeApp(config);

      this.analytics = getAnalytics();
    }
  }

  log(eventName, options = {}) {
    if (this.analytics) {
      logEvent(this.analytics, eventName, options);
    }
  }
}

export default new Analytics();
