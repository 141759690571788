import { useEffect, useRef } from 'react';

export default function (callback, delay = 5000) {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    if (!delay || delay === 0) {
      return;
    }

    const timeoutId = setTimeout(callbackRef.current, delay);

    return () => clearTimeout(timeoutId);
  }, [delay]);
}
