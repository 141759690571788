import { apiRouteExtension } from 'src/services/Api';
import { ConfirmEnrollmentView } from 'src/views';

export default apiRouteExtension({
  path: '/enroll/confirm-enrollment',
  element: <ConfirmEnrollmentView />,
  loader: ({ request, redirect }) => {
    const url = new URL(request.url);
    const EnrollmentType = url.searchParams.get('enrollment_type');
    const Description = url.searchParams.get('description');
    const size = url.searchParams.get('size');

    if (EnrollmentType && Description) {
      return { EnrollmentType, Description, size };
    } else {
      return redirect('/enroll/select-enrollment');
    }
  },
});
