import { apiRouteExtension } from 'src/services/Api';
import { ReceiptData } from 'src/services/Data/source';
import { ReceiptView } from 'src/views';

export default apiRouteExtension({
  path: '/receipt/:hash',
  element: <ReceiptView />,
  loader: async ({ params, fetch, datasource }) => {
    return await fetch(
      {
        method: 'get',
        url: `/transactions/${params.hash}/receipt`,
      },

      async ({ data, error }) => {
        if (error) {
          throw new Response('Your receipt was not found in our system.', {
            status: 404,
          });
        }

        const Receipt = datasource.create(ReceiptData, data);

        return {
          Receipt,
        };
      }
    );
  },
});
