export default [
  {
    label: 'United States',
    value: 'US',
  },
  {
    label: 'Outside the United States',
    value: 'Non-US',
  },
];
