import { WorkflowLayout } from 'src/layouts';
import AnalyticsService from 'src/services/Analytics';
import { Link } from 'react-router-dom';

export default function () {
  return (
    <WorkflowLayout
      title="You Are Already Enrolled"
      icon="check"
      controls={
        <>
          <a
            className="dm-button primary"
            href="https://www.digitalmint.io/bitcoin-atm-locations/"
            target="_blank"
            rel="noopener"
            data-testid="location"
            onClick={() => AnalyticsService.log('view_locations')}
          >
            Find a Location
          </a>

          <Link to="/transactions" className="dm-button secondary mt-16">
            Purchase History
          </Link>
        </>
      }
    >
      <p className="text-md text-normal">
        To purchase crypto start by finding a location.
      </p>
    </WorkflowLayout>
  );
}
