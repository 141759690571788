import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { NotificationsProvider } from 'src/services/Notifications';
import routes from 'src/routes';

const router = createBrowserRouter(routes);

export default function () {
  return (
    <NotificationsProvider>
      <RouterProvider router={router} />
    </NotificationsProvider>
  );
}
