import { useState } from 'react';
import { Link, useActionData, useNavigation } from 'react-router-dom';
import { WorkflowLayout } from 'src/layouts';
import { Button, TextInput } from '@digitalmint/web-components';
import { WorkflowLayoutProps } from 'src/layouts/Workflow';

export default function (props: WorkflowLayoutProps) {
  const [isDisabled, setIsDisabled] = useState(true);
  const errors: any = useActionData();
  const isLoading = useNavigation().state === 'submitting';

  return (
    <WorkflowLayout
      subtitle={props.subtitle}
      title="Enter your email address to get started."
      icon="laptop"
      errors={errors}
      controls={
        <>
          <Button
            className="mb-12"
            disabled={isDisabled}
            isLoading={isLoading}
            submit={true}
            text="Submit"
            testID="submit"
          />

          <Link to="/profile" className="dm-button secondary">
            Cancel
          </Link>
        </>
      }
    >
      <p className="text-nickel-500 text-center mb-40">
        Provide your email so we can send you secure messages for: account
        recovery, service updates and new locations.
      </p>
      <TextInput
        className="self-center mb-20"
        label="E-mail Address"
        name="Email"
        required={true}
        testID="Email"
        placeholder="alias@digitalmint.io"
        type="email"
        style={{ appearance: 'textfield' }}
        onChange={({ target }) => {
          setIsDisabled(target.value?.length < 1);
        }}
        error={!!errors?.Email}
        errorMessage={errors?.Email}
      />

      <TextInput
        className="self-center mb-20"
        label="Confirm E-mail Address"
        name="ConfirmEmail"
        required={true}
        testID="ConfirmEmail"
        placeholder="alias@digitalmint.io"
        type="email"
        style={{ appearance: 'textfield' }}
        onChange={({ target }) => {
          setIsDisabled(target.value?.length < 1);
        }}
        error={!!errors?.ConfirmEmail}
        errorMessage={errors?.ConfirmEmail}
      />
    </WorkflowLayout>
  );
}
