import { useRouteError } from 'react-router-dom';

export default function () {
  const error: any = useRouteError();

  return (
    <>
      <div className="m-auto">
        <h1 className="text-green-500 font-bold text-lg text-center">Oops!</h1>
      </div>

      <div className="m-auto">
        <p>Sorry, an unexpected error has occurred.</p>
        <p className="text-center mt-20 text-red-500">
          <i>{error.statusText || error.message}</i>
        </p>
      </div>
    </>
  );
}
