import NotificationsContext from '../Context';
import NotificationItem from './NotificationItem';

export default function () {
  return (
    <NotificationsContext.Consumer>
      {({ onDismiss, notifications }: any) => {
        return (
          <ul className="notifications-list">
            {notifications.map((notification, index) => {
              return (
                <li key={index} className="notifications-list-item">
                  <NotificationItem {...notification} onDismiss={onDismiss} />
                </li>
              );
            })}
          </ul>
        );
      }}
    </NotificationsContext.Consumer>
  );
}
