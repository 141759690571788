import { apiRouteExtension } from 'src/services/Api';
import {
  validateNumericString,
  validateEqualLength,
} from '@digitalmint/utilities/validators';
import { VerifyPhoneView } from 'src/views';
import AnalyticsService from 'src/services/Analytics';

export default apiRouteExtension({
  path: '/enroll/verify-phone',
  element: <VerifyPhoneView />,
  loader: ({ request, redirect }) => {
    const url = new URL(request.url);
    const PhoneNumber = url.searchParams.get('p');

    if (PhoneNumber) {
      return { PhoneNumber };
    } else {
      return redirect('/enroll/enter-phone');
    }
  },
  action: async ({ request, fetch, redirect }) => {
    const formData = await request.formData();
    const ConfirmCode = formData.get('ConfirmCode');
    const errors: any = {};

    if (!ConfirmCode) {
      errors.ConfirmCode = 'Verification Code is required.';

      return errors;
    }

    if (validateNumericString(ConfirmCode)) {
      if (!validateEqualLength(ConfirmCode, 6)) {
        errors.ConfirmCode = 'Verification Code must be 6 digits.';

        return errors;
      }
    } else {
      errors.ConfirmCode = 'Verification Code must be numbers.';

      return errors;
    }

    return await fetch(
      {
        url: '/verify',
        method: 'post',
        body: {
          ConfirmCode,
        },
      },
      ({ data, error, errorMessage }) => {
        if (error) {
          errors.submit = errorMessage;
          AnalyticsService.log('verify_phone_error');

          return errors;
        }

        AnalyticsService.log('sms_code_submission');

        // TODO: if already has an account and a password and already enrolled,
        // redirect to a message about already having everything
        if (data?.Status?.HasAccount && data?.Status?.HasPassword) {
          return redirect('/enroll/select-enrollment');
        } else {
          // Redirect to password creation before enrollment
          return redirect('/enroll/create-password');
        }
        // TODO: create a password for an existing and enrolled account
      }
    );
  },
});
