import { apiRouteExtension } from 'src/services/Api';
import { SignInView } from 'src/views';
import AuthService from 'src/services/Auth';
import AnalyticsService from 'src/services/Analytics';

export default apiRouteExtension({
  path: '/signin',
  element: <SignInView />,
  loader: async ({ redirect }) => {
    AnalyticsService.log('begin_signin');

    // NOTE: /signin is not an auth route,
    // so we must manually auth here as the
    // check would get ignored
    const isAuthenticated = await AuthService.check();

    if (isAuthenticated) {
      return redirect('/profile');
    } else {
      return false;
    }
  },
  action: async ({ request, redirect }) => {
    const formData = await request.formData();
    const PhoneNumber = formData.get('PhoneNumber');
    const Password = formData.get('Password');
    const errors: any = {};

    if (!PhoneNumber) {
      errors.PhoneNumber = 'Phone Number is required.';
      return errors;
    }

    if (!Password) {
      errors.Password = 'Password is required.';
      return errors;
    }

    const { error, errorMessage } = await AuthService.login({
      PhoneNumber,
      Password,
    });

    if (error) {
      errors.submit = errorMessage;
      AnalyticsService.log(errorMessage);

      return errors;
    } else {
      return redirect('/transactions');
    }
  },
});
