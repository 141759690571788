import { Link } from 'react-router-dom';
import WorkflowLayout from 'src/layouts/Workflow';

export default function () {
  return (
    <WorkflowLayout
      title="Your Session Has Expired"
      icon="warning"
      controls={
        <>
          <Link className="dm-button primary" data-testid="retry" to="/">
            Take Me Home
          </Link>
          <Link
            className="dm-button secondary mt-12"
            data-testid="retry"
            to="/contact-us"
          >
            Customer Support
          </Link>
        </>
      }
    >
      <p className="text-blue-600 text-sm text-normal">
        For your security, your session has expired after period of inactivity.
      </p>
    </WorkflowLayout>
  );
}
