import SiteHeader from './SiteHeader';
import { CONTACT } from '../../src/types';
import { formatPhoneNumber } from '@digitalmint/utilities/formatters';
import { useRouteError } from 'react-router-dom';

export default function () {
  const error: any = useRouteError();

  return (
    <div className="app-layout">
      <SiteHeader />
      <main className="app-content">
        <div className="m-auto">
          <h1 className="text-green-500 font-bold text-lg text-center">
            Oops!
          </h1>
        </div>

        <div className="text-center m-auto">
          <p className="text-center mt-20 text-red-500">
            <i>{error.data || error.statusText || error.message}</i>
          </p>
        </div>

        <div className="text-center mb-48">
          <h2 className="heading-3 text-green-500 mb-20">Customer Support</h2>

          <p className="text-center text-nickel-500">
            Hours: <span dangerouslySetInnerHTML={{ __html: CONTACT.HOURS }} />{' '}
          </p>
          <p className="text-center text-nickel-500">
            Phone:{' '}
            <a
              className="font-bold border-b-0 hover:border-b-0"
              href={`tel:${CONTACT.PHONE}`}
            >
              {formatPhoneNumber(CONTACT.PHONE)}
            </a>
          </p>
          <p className="text-center text-nickel-500 mb-12">
            Email:{' '}
            <a
              className="font-bold border-b-0 hover:border-b-0"
              href={`mailto:${CONTACT.EMAIL}`}
            >
              {CONTACT.EMAIL}
            </a>
          </p>
        </div>
      </main>
    </div>
  );
}
