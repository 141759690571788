import { useMemo } from 'react';
import { Button } from '@digitalmint/web-components';
import { Link, useNavigate } from 'react-router-dom';
import AnalyticsService from 'src/services/Analytics';

export default function () {
  const navigate = useNavigate();

  const appVersion = useMemo(() => {
    return import.meta.env.VITE_APP_VERSION;
  }, []);

  return (
    <div className="flex flex-col m-0 grow items-center justify-between w-full">
      <header>
        <h1 className="text-green-500 font-bold text-lg text-center">
          DigitalMint Bitcoin ATM and Teller Windows
        </h1>
      </header>

      <h2 className="text-blue-600 text-center text-md mt-20">
        Buy Bitcoin and Litecoin Instantly with cash at our locations or online.
      </h2>

      <div className="text-center">
        <p className="text-nickel-500 font-bold">Need more information?</p>
        <p className="text-nickel-500 font-bold mb-20">
          Visit our
          <a
            className="text-nickel-500 pl-4 pr-4"
            href="https://www.digitalmint.io/customer-support/faqs"
            target="_blank"
            rel="noopener"
            data-testid="faq"
            onClick={() => AnalyticsService.log('view_faq')}
          >
            FAQ
          </a>
          ,
          <Link
            className="text-nickel-500 pl-4 pr-4"
            to="/contact-us"
            onClick={() => AnalyticsService.log('view_contacts')}
          >
            Contact Us
          </Link>
          or
          <a
            className="text-nickel-500 pl-4 pr-4"
            href="https://www.digitalmint.io/bitcoin-atm-locations/"
            target="_blank"
            rel="noopener"
            data-testid="location"
            onClick={() => AnalyticsService.log('view_locations')}
          >
            Find a Location
          </a>
        </p>

        <Button
          onClick={() => {
            AnalyticsService.log('begin_web_enrollment');
            navigate('/enroll');
          }}
          className="mb-12"
          testID="submit"
          text="Sign Up"
        />

        <Button
          onClick={() => {
            navigate('/signin');
          }}
          testID="submit"
          text="Sign In"
          className="secondary"
        />

        <p className="text-nickel-500 text-center mt-20">
          <a
            href="https://www.digitalmint.io/terms-of-service/"
            target="_blank"
            rel="noopener"
            data-testid="privacy"
            onClick={() => AnalyticsService.log('view_tos')}
          >
            DigitalMint Privacy Policy
          </a>
        </p>
        <p className="mt-20 text-center text-2xs text-blue-900">
          Version {appVersion}
        </p>
      </div>
    </div>
  );
}
