export default {
  create(source: any, fields: any): any {
    if (fields && source) {
        const Data = new source();
        Data.set(fields);
        return Data.attributes;
    }
  },

  createList(source: any, list = []) {
    if (source) {
      try {
        return list?.reduce((list: any[], fields: any) => {
          const Data = new source();

          Data.set(fields);

          list.push(Data.attributes);

          return list;
        }, []);
      } catch (_error) {
        return [];
      }
    }
  },
};
