import ApiService from 'src/services/Api';
import checkExpiration from 'src/services/Api/checkExpiration';
import AuthService from 'src/services/Auth';
import DataService from 'src/services/Data';
import { redirect } from 'react-router-dom';

// NOTE: Use this route extension on
// every route that:
// - Must be authenticated, or
// - Tracks sessions outside of signin, such as enrollment
// - Uses an action or a loader and requires
//   one of the first two
// - You want to use the built-in services
export default class RouteExtension {
  auth = false;
  propsAction: () => any
  propsLoader: () => any

  constructor(props: any = {}) {
    this.propsAction = props.action;
    this.propsLoader = props.loader;
    this.auth = !!props.auth;

    delete props.action;
    delete props.loader;
    delete props.auth;

    return {
      ...props,
      action: this.action.bind(this),
      loader: this.loader.bind(this),
    };
  }

  async fetch(options = {}, callback) {
    if (!callback) {
      throw Error('apiRouteExtension: fetch requires a callback');
    } else {
      const response = await ApiService.fetch(options);

      // General session error handling
      if (response.status === 401) {
        if (checkExpiration(response.data)) {
          // Checks the codestring from the 401 for expired sessions
          // for non-signin authentication, such as enrollment
          return redirect('/session-expired');
        } else {
          // General 401 authentication errors would expect you to signin
          return redirect('/signin');
        }
      } else {
        return callback(response);
      }
    }
  }

  async action(...args) {
    return await this.processRouteMethod(this.propsAction, args);
  }

  async loader(...args) {
    // Check authentication before page load on
    // auth-required pages to redirect appropriately
    // before loading a screen. Do this for every route,
    // even if a loader is not defined in the config.
    if (this.auth) {
      const isAuthenticated = await AuthService.check();

      if (!isAuthenticated) {
        return redirect('/signin');
      } else {
        return this.processRouteMethod(this.propsLoader, args);
      }
    } else {
      return this.processRouteMethod(this.propsLoader, args);
    }
  }

  async processRouteMethod(routeMethod, args) {
    if (routeMethod) {
      return await routeMethod({
        ...args[0],
        datasource: {
          create: DataService.create,
          createList: DataService.createList,
        },
        fetch: this.fetch.bind(this),
        redirect,
      });
    } else {
      return false;
    }
  }
}
