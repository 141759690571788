import alert from './alert.svg';
import warn from './warn.svg';
import success from './success.svg';
import info from './info.svg';

export default {
  alert,
  warn,
  success,
  info,
};
