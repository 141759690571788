import { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon } from '@digitalmint/web-components';
import { Color } from 'src/utils';

type PopupSetup = {
  onClosePopup: () => void
}

type PopupArgs = {
  content: string | ((opts: PopupSetup) => React.ReactNode)
  controls: string | ((opts: PopupSetup) => React.ReactNode)
  icon?: string
  onClose?: () => void
  testID: string
  text: string | React.ReactNode
  title: string
}

export default function Popup({
  content,
  controls = 'Close',
  icon,
  onClose,
  testID = 'xPopup',
  text,
  title,
}: PopupArgs ) {
  const [show, setShow] = useState(false);

  const checkContent = () => {
    if (typeof content === 'string') {
      return <p>{content}</p>;
    }

    if (typeof content === 'function') {
      return content({
        onClosePopup: () => {
          if (onClose) {
            onClose();
          }

          setShow(false);
        },
      });
    }

    return content;
  };

  const checkControls = () => {
    if (typeof controls === 'function') {
      return controls({
        onClosePopup: () => {
          if (onClose) {
            onClose();
          }

          setShow(false);
        },
      });
    } else {
      return (
        <Button
          onClick={() => {
            if (onClose) {
              onClose();
            }

            setShow(false);
          }}
          testID={`${testID}-control`}
          text={controls}
          type="primary"
        />
      );
    }
  };

  return (
    <>
      <Button
        aria-expanded={show}
        onClick={() => setShow(true)}
        type="link"
        testID={`${testID}-open`}
        text={text}
      />
      {show && (
        <div
          data-testid={`${testID}-container`}
          role="alertdialog"
          aria-modal="true"
          className="flex flex-col justify-center items-center fixed inset-0 z-100 outline-none focus:outline-none backdrop-blur-[2px] select-none"
        >
          <div className="flex flex-col max-h-2/3 lg:max-h-1/2 w-4/5 lg:w-1/3 justify-between items-center p-32 rounded-xl bg-white shadow-[0_8px_28px_0px_rgba(0,0,0,0.25)] text-center">
            <header className="flex flex-col items-center">
              {icon && (
                <Icon
                  className="mb-8"
                  color={Color.blue[500]}
                  icon={icon}
                  height="48"
                  width="48"
                />
              )}
              <h4 className="heading-3">{title}</h4>
            </header>
            <section className="mt-20 mb-32 overflow-y-auto">
              {checkContent()}
            </section>

            <div className="flex flex-col justify-center items-center w-full">
              {checkControls()}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

Popup.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  controls: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  icon: PropTypes.string,
  onClose: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  testID: PropTypes.string,
  title: PropTypes.string.isRequired,
};
