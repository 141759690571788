import { apiRouteExtension } from 'src/services/Api';
import { SelectEnrollmentView } from 'src/views';

export default apiRouteExtension({
  path: '/enroll/select-enrollment',
  element: <SelectEnrollmentView />,
  loader: async ({ fetch, redirect }) => {
    return await fetch(
      {
        method: 'get',
        url: '/profile/enrollment-types',
      },
      ({ data, error }) => {
        if (error) {
          return redirect('/enroll/error');
        }

        // A collection of enrollment types that
        // the customer has not yet enrolled in
        const Available =
          data?.EnrollmentTypes?.filter(
            ({ Approved, Submitted }) => !Approved && !Submitted
          ) || [];

        // A collection of enrollment types that have been approved
        // and should not be avialable in the list on the select screen
        const Approved =
          data?.EnrollmentTypes?.filter(
            ({ Approved, Submitted }) => Approved && Submitted
          ) || [];

        // A collection of enrollment types that the customer
        // is either denied or waiting approval and should not
        // be included in the list on the select
        const Unapproved =
          data?.EnrollmentTypes?.filter(
            ({ Approved, Submitted }) => !Approved && Submitted
          ) || [];

        if (Available.length === 1) {
          // If only one available enrollment type exists,
          // automatically select it and move the user along
          // to the confirmation screen
          const { Description, Type } = Available[0];

          const path = encodeURI(
            `/enroll/confirm-enrollment?enrollment_type=${Type}&description=${Description}`
          );

          return redirect(path);
        } else if (Approved.length >= 1 && Available.length === 0) {
          // If no available enrollments exist, the user has already
          // enrolled in all possible types, inform them they are
          // already enrolled
          return redirect('/enroll/enrolled');
        } else if (
          Unapproved.length >= 1 &&
          Available.length === 0 &&
          Approved.length === 0
        ) {
          // If there's no available enrollment types to choose, no approved
          // enrollment types and only unapproved ones, inform the user
          // that they are not approved
          return redirect('/enroll/unapproved');
        } else {
          // Return all available enrollment types on the select screen
          return { EnrollmentTypes: Available };
        }
      }
    );
  },
  action: async ({ request, redirect }) => {
    const formData = await request.formData();
    const EnrollmentType = formData.get('EnrollmentType');
    const Description = formData.get('Description');
    const size = formData.get('size');

    const path = encodeURI(
      `/enroll/confirm-enrollment?enrollment_type=${EnrollmentType}&description=${Description}&size=${size}`
    );

    return redirect(path);
  },
});
