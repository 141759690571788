import { useState, useEffect } from 'react';
import { Icon } from '@digitalmint/web-components';
import { Color } from 'src/utils';
import { TRANSACTION_STATES } from 'src/types';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const PUBLISHED_STATE_CODES = [
  TRANSACTION_STATES.PENDING_BATCH.code,
  TRANSACTION_STATES.PENDING_CONFIRMATION.code,
  TRANSACTION_STATES.SETTLED.code,
];
const PROCESSING_STATE_CODES = [
  TRANSACTION_STATES.PENDING_CONFIRMATION.code,
  TRANSACTION_STATES.SETTLED.code,
];
const CONFIRMED_STATE_CODES = [TRANSACTION_STATES.SETTLED.code];
const CANCELLED_STATE_CODES = [TRANSACTION_STATES.CANCELLED.code];
const FROZEN_STATE_CODES = [TRANSACTION_STATES.FROZEN.code];

const STATE_CLASSES = {
  [TRANSACTION_STATES.PENDING_BATCH.code]: 'complete',
  [TRANSACTION_STATES.PENDING_CONFIRMATION.code]: 'complete',
  [TRANSACTION_STATES.SETTLED.code]: 'complete',
  [TRANSACTION_STATES.CANCELLED.code]: 'cancelled',
  [TRANSACTION_STATES.FROZEN.code]: 'frozen',
};

const StepBar = ({ State, codes, label }) => {
  const iconCodes = codes.slice();

  if (
    iconCodes.includes(TRANSACTION_STATES.CANCELLED.code) ||
    iconCodes.includes(TRANSACTION_STATES.FROZEN.code)
  ) {
    iconCodes.pop(State);
  }

  return (
    <div
      className={classnames(
        'step-bar',
        codes.includes(State) && STATE_CLASSES[State]
      )}
    >
      <div className="bar">
        <Icon
          color={Color.white}
          icon={iconCodes.includes(State) ? 'checkmark' : 'xmark'}
          width="16"
          height="16"
        />
      </div>
      <span className="label">{label}</span>
    </div>
  );
};

StepBar.propTypes = {
  State: PropTypes.number,
  codes: PropTypes.array,
  label: PropTypes.string,
};

const PROGRESS_PERCENTAGES = {
  [TRANSACTION_STATES.PENDING_BATCH.code]: '25%',
  [TRANSACTION_STATES.PENDING_CONFIRMATION.code]: '50%',
  [TRANSACTION_STATES.SETTLED.code]: '100%',
};

const ProgressBar = ({ State }: { State: number }) => {
  const [width, setWidth] = useState('0');
  const [progress, setProgress] = useState('');
  const [iconColor, setIconColor] = useState(Color.gray[500]);

  useEffect(() => {
    setWidth(PROGRESS_PERCENTAGES[State] || '0');
    setProgress(STATE_CLASSES[State]);
    if (State === TRANSACTION_STATES.SETTLED.code) {
      setIconColor(Color.green[500]);
    }
  }, [State]);

  return (
    <div className="progress-bar">
      <div className={classnames('progress', progress)} style={{ width }} />
      <div className="track" />
      <Icon color={iconColor} icon="wallet" width="32" height="32" />
    </div>
  );
};

ProgressBar.propTypes = {
  State: PropTypes.number,
};

const TransactionState = ({ State }) => {
  const renderSteps = state => {
    switch (state) {
      case TRANSACTION_STATES.CANCELLED.code:
        return (
          <StepBar
            State={state}
            codes={CANCELLED_STATE_CODES}
            label="Cancelled"
          />
        );
      case TRANSACTION_STATES.FROZEN.code:
        return (
          <StepBar State={state} codes={FROZEN_STATE_CODES} label="Frozen" />
        );
      default:
        return (
          <>
            <StepBar
              State={state}
              codes={PUBLISHED_STATE_CODES}
              label="Published"
            />
            <StepBar
              State={state}
              codes={PROCESSING_STATE_CODES}
              label="Processing"
            />
            <StepBar
              State={state}
              codes={CONFIRMED_STATE_CODES}
              label="Confirmed"
            />
          </>
        );
    }
  };

  return (
    <div className="transaction-state-component">
      <ProgressBar State={State} />
      <div className="steps">{renderSteps(State)}</div>
    </div>
  );
};

TransactionState.propTypes = {
  State: PropTypes.number,
};

export default TransactionState;
