import { useMemo } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { startCase, toUpper } from 'lodash';
import { Icon } from '@digitalmint/web-components';
import { Color } from 'src/utils';
import { TRANSACTION_STATES } from 'src/types';

export default function TransactionItem({
  Base,
  BaseQuantity,
  CreatedAt,
  Hash,
  Side,
  State,
  StateDesc,
}) {
  const description = useMemo(() => {
    let textColor, iconColor, icon;
    switch (State) {
      case TRANSACTION_STATES.SETTLED.code:
      case TRANSACTION_STATES.PENDING_CONFIRMATION.code:
        textColor = 'text-green-500';
        iconColor = Color.green[500];
        icon = 'check';
        break;
      case TRANSACTION_STATES.CANCELLED.code:
        textColor = 'text-red-500';
        iconColor = Color.red[500];
        icon = 'xmark';
        break;
      default: // Pending
        textColor = 'text-gray-600';
        iconColor = Color.gray[600];
        icon = 'info';
    }

    return (
      <>
        <p className={textColor}>{startCase(StateDesc)}</p>
        <Icon
          className="ml-8"
          icon={icon}
          color={iconColor}
          height="16"
          width="16"
        />
      </>
    );
  }, [State]);

  const BaseName = useMemo(() => startCase(Base?.Name), [Base?.Name]);
  const BaseSymbol = useMemo(() => toUpper(Base?.Symbol), [Base?.Symbol]);
  const Created = useMemo(() => moment(CreatedAt).fromNow(), [CreatedAt]);
  const TransactionSide = useMemo(() => startCase(Side), [Side]);
  const BaseQtyFloat = parseFloat(BaseQuantity);

  return (
    <Link className="no-underline" to={`/receipt/${Hash}`} data-testid={Hash}>
      <li className="flex flex-row items-center space-between border-b-2 border-gray-500  px-8 pt-12">
        <div className="justify-self-start ">
          <Icon
            className="mr-4"
            icon="receive"
            color={Color.green[500]}
            height="20"
            width="20"
          />
        </div>
        <div className="flex flex-col flex-grow space-between ml-12">
          <div className="flex flex-row justify-between items-center">
            <p className="m-0 text-black font-semibold">{`${TransactionSide} ${BaseName}`}</p>
            <span className="flex flex-row">
              <Icon
                className="mt-4"
                color={Color.green[300]}
                icon="plus"
                height="16"
                width="16"
              />
              <p className="m-0 text-black">{BaseQtyFloat.toFixed(8)}</p>
              <p className="mb-0 ml-4 text-black text-sm">{BaseSymbol}</p>
            </span>
          </div>
          <div className="flex flex-row justify-between mb-4 leading-none mt-4">
            <p className="text-gray-600">{Created}</p>
            <span className="flex flex-row items-centered">{description}</span>
          </div>
        </div>
      </li>
    </Link>
  );
}

TransactionItem.propTypes = {
  Base: PropTypes.shape({
    Name: PropTypes.string,
    Symbol: PropTypes.string,
  }),
  BaseQuantity: PropTypes.number,
  CreatedAt: PropTypes.string,
  Hash: PropTypes.string,
  Side: PropTypes.string,
  State: PropTypes.number,
  StateDesc: PropTypes.string,
};
