export const NOTIFICATION = {
  ALERT: 'alert',
  INFO: 'info',
  SUCCESS: 'success',
  WARN: 'warn',
};

export const TIMEOUTS = [NOTIFICATION.ALERT, NOTIFICATION.SUCCESS];

export const DISMISSABLES = [NOTIFICATION.INFO, NOTIFICATION.WARN];

export const PRIORITY = {
  alert: 1,
  info: 4,
  success: 2,
  warn: 3,
};

export const TIMEOUT_DELAY = 8000;

export const ASSERT_TYPE = {
  alert: 'assertive',
  info: 'polite',
  success: 'assertive',
  warn: 'assertive',
};
