import { useNavigate } from 'react-router-dom';
import { Color } from 'src/utils';
import { Icon } from '@digitalmint/web-components';

export default function () {
  const navigate = useNavigate();

  return (
    <button
      aria-label="Go Back"
      className="border-0"
      onClick={() => navigate(-1)}
      data-testid="back-button"
    >
      <Icon height="40" width="40" color={Color.green[500]} icon="arrowPrev" />
      <span className="sr-only">Go Back</span>
    </button>
  );
}
