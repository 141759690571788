import { Link, useLoaderData } from 'react-router-dom';
import moment from 'moment';
import { DataFields, Icon } from '@digitalmint/web-components';
import { WorkflowLayout } from 'src/layouts';
import { formatPhoneNumber } from '@digitalmint/utilities/formatters';
import { WorkflowLayoutProps } from 'src/layouts/Workflow';
import { useApi } from 'src/services/Api';
import { useNotifications } from 'src/services/Notifications';

export default function (props: WorkflowLayoutProps) {
  const notify = useNotifications();

  const [handleResendCode] = useApi({
    method: 'post',
    url: '/verify/resend',
    onError(message: string) {
      notify.alert(message);
    },
    onSuccess() {
      notify.success('A verification code has been sent to you by text.');
    },
  });

  const { Profile }: any = useLoaderData();
  return (
    <WorkflowLayout title="Personal Info" subtitle={props.subtitle}>
      <DataFields
        fields={[
          {
            label: (
              <span className="flex flex-row items-center">
                <Icon className="mr-8" icon="profile" height="20" width="20" />
                PIN
              </span>
            ),
            value: (
              <span className="flex flex-col items-end">
                {Profile.HasPIN}
                <Link
                  to="/update/pin/verify-phone"
                  data-testid="link-pin"
                  onClick={async () => await handleResendCode()}
                >
                  Update PIN
                </Link>
              </span>
            ),
          },
          {
            label: (
              <span className="flex flex-row items-center">
                <Icon className="mr-8" icon="lock2" height="20" width="20" />
                Password
              </span>
            ),
            value: (
              <span className="flex flex-col items-end">
                <Link
                  to="/update/password/verify-phone"
                  data-testid="link-password"
                  onClick={async () => await handleResendCode()}
                >
                  Update Password
                </Link>
              </span>
            ),
          },
          {
            label: (
              <span className="flex flex-row items-center">
                <Icon className="mr-8" icon="profile" height="20" width="20" />
                Name
              </span>
            ),
            value: (
              <div>
                {Profile.FirstName} {Profile.LastName}
              </div>
            ),
          },
          {
            label: (
              <span className="flex flex-row items-center">
                <Icon className="mr-8" icon="mail" height="20" width="20" />
                Email
              </span>
            ),
            value: (
              <span className="flex flex-col items-end">
                {Profile.Email}
                <Link
                  to="/update/email/verify-phone"
                  data-testid="update-email"
                  onClick={async () => await handleResendCode()}
                >
                  Update Email
                </Link>
              </span>
            ),
          },
          {
            label: (
              <span className="flex flex-row items-center">
                <Icon className="mr-8" icon="phone" height="20" width="20" />
                Phone
              </span>
            ),
            value: formatPhoneNumber(Profile.PhoneNumber),
          },
          {
            label: (
              <span className="flex flex-row is-vcentered">
                <Icon className="mr-8" icon="id" height="25" width="25" />
                Birth Date
              </span>
            ),

            value: moment(new Date(Profile.DOB)).format('MM/DD/YYYY'),
          },
          {
            label: (
              <span className="flex flex-row is-vcentered">
                <Icon className="mr-8" icon="location" height="20" width="20" />
                Address
              </span>
            ),
            value: (
              <address className="text-right not-italic">
                {Profile?.Address ? (
                  <>
                    {Profile.Address.Street}
                    {Profile.Address.Street2}
                    <br />
                    {Profile.Address.City}, {Profile.Address.StateCode}
                  </>
                ) : (
                  <p>-</p>
                )}
              </address>
            ),
          },
          {
            label: (
              <span className="flex flex-row is-vcentered">
                <Icon className="mr-8" icon="location" height="20" width="20" />
                Country
              </span>
            ),
            value: Profile.Address?.Country || '-',
          },
        ]}
      />
    </WorkflowLayout>
  );
}
