import { apiRouteExtension } from 'src/services/Api';
import AuthService from 'src/services/Auth';
import { ProfileView } from 'src/views';
import { ProfilePath, SelectEnrollmentPath } from 'src/routes/paths';

export default apiRouteExtension({
  path: ProfilePath,
  element: <ProfileView />,
  auth: true,
  loader: async ({ redirect }) => {
    if (!AuthService.Profile?.DOB) {
      return redirect(SelectEnrollmentPath);
    }

    return {
      Profile: AuthService.Profile,
    };
  },
});
