import { useState } from 'react';
import { useActionData, useNavigation, Link } from 'react-router-dom';
import { Button, TextInput } from '@digitalmint/web-components';
import WorkflowLayout, { WorkflowLayoutProps } from 'src/layouts/Workflow';
import { WalletPopup } from 'src/components/popups';

export default function (props: WorkflowLayoutProps) {
  const [isDisabled, setIsDisabled] = useState(true);
  const errors: any = useActionData();
  const isLoading = useNavigation().state === 'submitting';

  return (
    <WorkflowLayout
      subtitle={props.subtitle}
      title="Enter Your Default Wallet"
      method="post"
      errors={errors}
      controls={
        <>
          <Button
            className="mb-12"
            disabled={isDisabled}
            isLoading={isLoading}
            text="Submit"
            testID="submit"
            submit={true}
          />
          <Link
            className="text-sm font-bold mt-24"
            data-testid="cancel"
            to="/profile"
          >
            Skip
          </Link>
        </>
      }
    >
      <div className="mb-[60px]">
        <p className="text-blue-600 text-sm text-normal mb-12">
          Debit purchases require having a wallet address saved in advance.
        </p>

        <WalletPopup />
      </div>

      <TextInput
        className="self-center mb-20"
        label="Default Wallet"
        name="WalletAddress"
        required={true}
        testID="WalletAddress"
        onChange={({ target }) => setIsDisabled(target.value?.length < 1)}
        error={!!errors?.WalletAddress}
        errorMessage={errors?.WalletAddress}
      />
      {errors?.WalletAddress && (
        <Link to="/enroll/default-wallet" data-testid="differentWallet">
          Try a Different Wallet
        </Link>
      )}
    </WorkflowLayout>
  );
}
