import { apiRouteExtension } from 'src/services/Api';
import { EnterDefaultWalletView } from 'src/views';
import AnalyticsService from 'src/services/Analytics';
import { WalletVerificationData } from 'src/services/Data/source';

export default apiRouteExtension({
  path: '/enroll/default-wallet',
  element: <EnterDefaultWalletView />,
  action: async ({ request, redirect, fetch, datasource }) => {
    const formData = await request.formData();
    const WalletAddress = formData.get('WalletAddress');
    const errors: any = {};

    return await fetch(
      {
        method: 'POST',
        url: '/verify-wallet',
        body: {
          WalletAddress,
          Symbol: 'BTCUSD',
        },
      },
      async ({ data, error, errorMessage }) => {
        let analyticsMessage = 'verify_wallet_';
        if (error) {
          if (errorMessage === 'Invalid') {
            errors.submit = 'This wallet is invalid';
            analyticsMessage += 'invalid_error';
          } else if (errorMessage === 'Flagged') {
            analyticsMessage += 'flagged_error';
            errors.submit = 'This wallet address cannot be used';
          }

          AnalyticsService.log(analyticsMessage);
          return errors;
        }

        const WalletVerification = datasource.create(
          WalletVerificationData,
          data
        );

        if (WalletVerification.isVerified) {
          return redirect(
            `/enroll/default-wallet-confirm?default_wallet=${WalletAddress}`
          );
        } else {
          errors.WalletAddress = 'This wallet cannot be verified';

          return errors;
        }
      }
    );
  },
});
