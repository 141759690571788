import Popup from '../Popup';
import ios from 'src/images/applestore.png';
import android from 'src/images/googleplay.png';
import ApiService from 'src/services/Api';
import { useNotifications } from 'src/services/Notifications';

export default function () {
  const notify = useNotifications();

  const handleWalletRecommendation = async body => {
    const { success } = await ApiService.fetch({
      method: 'post',
      url: '/wallet-recommend',
      body,
    });

    if (success) {
      notify.success(
        'A link to download a wallet has been sent to your phone.'
      );
    } else {
      notify.alert('Sorry, we could not send you a link at this time.');
    }
  };

  return (
    <Popup
      text="Don't have a wallet?"
      title="Download a Wallet"
      controls="Close"
      testID="wallet-popup"
      content={({ onClosePopup }) => {
        return (
          <>
            <section className="flex flex-col items-center">
              <p className="mb-20">
                Choose from one of the wallets below and recieve a download link
                in a text message to your phone.
              </p>

              <button
                data-testid="ios"
                className="mb-12"
                onClick={async event => {
                  event.preventDefault();

                  await handleWalletRecommendation({
                    Platform: 'iOS',
                    Symbol: 'BTCUSD',
                  });

                  onClosePopup();
                }}
              >
                <img
                  width="200"
                  src={ios}
                  alt="Download a BTC wallet for iOS"
                />
              </button>

              <button
                data-testid="android"
                onClick={async event => {
                  event.preventDefault();

                  await handleWalletRecommendation({
                    Platform: 'Android',
                    Symbol: 'BTCUSD',
                  });

                  onClosePopup();
                }}
              >
                <img
                  width="200"
                  src={android}
                  alt="Download a BTC wallet for Android"
                />
              </button>
            </section>
          </>
        );
      }}
    />
  );
}
