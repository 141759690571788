import { useState } from 'react';
import { useActionData, useLoaderData, useNavigation } from 'react-router-dom';
import { Button, SecureTextInput } from '@digitalmint/web-components';
import { formatPhoneNumber } from '@digitalmint/utilities/formatters';
import { WorkflowLayout } from 'src/layouts';
import { WorkflowLayoutProps } from 'src/layouts/Workflow';
import { useApi } from 'src/services/Api';
import { useNotifications } from 'src/services/Notifications';

export default function (props: WorkflowLayoutProps): JSX.Element {
  const [isDisabled, setIsDisabled] = useState(true);
  const errors: any = useActionData();
  const isLoading = useNavigation().state === 'submitting';
  const { PhoneNumber }: any = useLoaderData();
  const notify = useNotifications();

  const [handleResendCode] = useApi({
    method: 'post',
    url: '/verify/resend',
    onError(message: string) {
      notify.alert(message);
    },
    onSuccess() {
      notify.success('A verification code has been sent to you by text.');
    },
  });

  return (
    <WorkflowLayout
      subtitle={props.subtitle}
      title={`Enter the SMS code texted to your phone at ${formatPhoneNumber(
        PhoneNumber
      )}`}
      icon="lock"
      errors={errors}
      controls={
        <Button
          disabled={isDisabled}
          isLoading={isLoading}
          submit={true}
          text="Continue"
          testID="submit"
        />
      }
    >
      <SecureTextInput
        className="self-center mb-20"
        label="Verification Code"
        name="ConfirmCode"
        required={true}
        maxLength={6}
        testID="ConfirmCode"
        type="number"
        style={{ appearance: 'textfield' }}
        onChange={({ target }) => {
          setIsDisabled(target.value?.length < 1);
        }}
        error={!!errors?.ConfirmCode}
        errorMessage={errors?.ConfirmCode}
      />

      <Button
        onClick={async () => await handleResendCode()}
        type="link"
        testID="resend"
        text="Resend Code"
      />
    </WorkflowLayout>
  );
}
