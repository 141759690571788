import CreatePasswordRoute from './Create';
import EnterPhoneRoute from './EnterPhone';
import PasswordSuccessRoute from './Success';
import VerifyPhoneRoute from './VerifyPhone';

export default [
  CreatePasswordRoute,
  EnterPhoneRoute,
  PasswordSuccessRoute,
  VerifyPhoneRoute,
];
