/* eslint valid-typeof: 'off' */

export default class Data {
  attributes = {};

  constructor() {
    // setup configs for reference
    const { computed, hasMany, hasOne, properties } = (this.config = {
      computed: this.computed && this.computed(),
      hasMany: this.hasMany && this.hasMany(),
      hasOne: this.hasOne && this.hasOne(),
      properties: this.properties && this.properties(),
    });
    // properties undefined or defaultValue until set
    for (const key in properties) {
      this.attributes[key] = properties[key].defaultValue;
    }
    // hasMany empty array until set
    for (const key in hasMany) {
      this.attributes[key] = [];
    }
    // hasOne undefined until set
    for (const key in hasOne) {
      this.attributes[key] = undefined;
    }
    // computeds undefined until set
    for (const key in computed) {
      this.attributes[key] = undefined;
    }
  }

  set(data = {}) {
    // ignore anything but an object that isn't an array or null
    if (!data || typeof data !== 'object' || Array.isArray(data)) {
      return this.attributes;
    }

    const { computed, hasMany, hasOne, properties } = this.config;

    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value) && hasMany && hasMany[key]) {
        const Data = hasMany[key];

        // create a new data set for each hasMany relationship
        this.attributes[key] = value.reduce((arr, attr) => {
          const data = new Data();

          data.set(attr);

          arr.push(data.attributes);

          return arr;
        }, []);
      } else if (typeof value === 'object' && hasOne && hasOne[key]) {
        // create a new data set for a hasOne relationship
        const Data = hasOne[key];

        const data = new Data();

        data.set(value);

        this.attributes[key] = data.attributes;
      } else if (properties && properties[key]) {
        // set values for properties
        this.attributes[key] = value;

        const { type } = properties[key];

        // soft type-check
        if (value !== null && value !== undefined) {
          if (
            (typeof value === 'object' &&
              type === 'array' &&
              !Array.isArray(value)) ||
            (typeof value !== type && !Array.isArray(value))
          ) {
            console.warn(
              `Property ${key} is ${typeof value}, but should be ${type}`
            );
          }
        }
      }
    }

    if (computed) {
      // execute computed functions
      for (const [key, func] of Object.entries(computed)) {
        this.attributes[key] = func(this.attributes || {});
      }
    }
  }
}
