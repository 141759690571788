import { WorkflowLayout } from 'src/layouts';
import AnalyticsService from 'src/services/Analytics';

export default function () {
  // TODO: log event for going to locations page
  return (
    <WorkflowLayout
      title="Success!"
      icon="check"
      controls={
        <a
          className="dm-button primary"
          href="https://www.digitalmint.io/bitcoin-atm-locations/"
          target="_blank"
          rel="noopener"
          data-testid="location"
          onClick={() => AnalyticsService.log('view_locations')}
        >
          Find a Location
        </a>
      }
    >
      <p className="text-md text-normal">
        To purchase crypto start by finding a location.
      </p>
    </WorkflowLayout>
  );
}
