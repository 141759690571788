let hasOwn = function(obj: any, prop: any): boolean {
  return obj.hasOwnProperty(prop);
}
if (Object['hasOwn']) {
  hasOwn = Object['hasOwn']
}

export default function (name, struct) {
  const data = Object.keys(struct).reduce((obj, key) => {
    obj[key] = struct[key].default;

    return obj;
  }, {});

  return new Proxy(data, {
    get(obj, prop) {
      if (!hasOwn(data, prop)) {
        throw new Error(`${name}: ${String(prop)} is not a supported property.`);
      } else {
        return Reflect.get(obj, prop);
      }
    },
    set(obj, prop, value) {
      if (!hasOwn(data, prop)) {
        throw new Error(`${name}: ${String(prop)} is not a supported property.`);
      } else if (value === undefined || value === null) {
        return Reflect.set(obj, prop, struct[prop].default);
      } else if (typeof value !== struct[prop].type) {
        throw new Error(`${name}: ${value} is not type ${struct[prop].type}.`);
      } else {
        return Reflect.set(obj, prop, value);
      }
    },
  });
}
