import { apiRouteExtension } from 'src/services/Api';
import {
  validateNumericString,
  validateMaxLength,
} from '@digitalmint/utilities/validators';
import { VerifyEmailView } from 'src/views';
import AnalyticsService from 'src/services/Analytics';

export default apiRouteExtension({
  path: '/enroll/verify-email',
  element: <VerifyEmailView />,
  action: async ({ request, fetch, redirect }) => {
    const formData = await request.formData();
    const ConfirmCode = formData.get('ConfirmCode');
    const errors: any = {};

    if (!ConfirmCode) {
      errors.ConfirmCode = 'Confirmation Code is required.';

      return errors;
    }

    if (validateNumericString(ConfirmCode)) {
      if (!validateMaxLength(ConfirmCode, 6)) {
        errors.ConfirmCode = 'Confirmation Code must be less than 6 digits.';

        return errors;
      }
    } else {
      errors.ConfirmCode = 'Confirmation Code must be numbers.';

      return errors;
    }

    return await fetch(
      {
        method: 'post',
        url: '/email/verify',
        body: { ConfirmCode },
      },
      ({ error, errorMessage }) => {
        if (error) {
          errors.submit = errorMessage;
          AnalyticsService.log('verify_email_error');

          return errors;
        } else {
          return redirect('/update/email/success');
        }
      }
    );
  },
});
