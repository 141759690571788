import { useMemo } from 'react';
import PropTypes from 'prop-types';

export default function UserLayout({ children, header }) {
  const checkHeader = useMemo(() => {
    if (typeof header === 'string') {
      return <h1 className="heading-3">{header}</h1>;
    }

    return header;
  }, [header]);

  return (
    <>
      <header className="mb-20 text-center">{checkHeader}</header>
      <div className="flex flex-col m-0 grow items-center justify-center w-full pb-20">
        {children}
      </div>
    </>
  );
}

UserLayout.propTypes = {
  children: PropTypes.node,
  errors: PropTypes.object,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};
