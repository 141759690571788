import { apiRouteExtension } from 'src/services/Api';
import { EnrollStartView } from 'src/views';
import AnalyticsService from 'src/services/Analytics';

const STATE_BLACKLIST = ['NY'];
const COUNTRY_BLACKLIST = ['Non-US'];

export default apiRouteExtension({
  path: '/enroll',
  element: <EnrollStartView />,
  action: async ({ fetch, request, redirect }) => {
    const formData = await request.formData();
    const State = formData.get('State');
    const Country = formData.get('Country');
    const errors: any = {};

    if (COUNTRY_BLACKLIST.includes(Country)) {
      errors.Country = true;

      return errors;
    } else if (STATE_BLACKLIST.includes(State)) {
      const path = encodeURI(
        `/enroll/signup?country=${Country}&state=${State}`
      );

      return redirect(path);
    } else {
      return await fetch(
        {
          method: 'post',
          url: '/enroll/screening',
          body: { State, Country },
        },
        ({ error, errorMessage }) => {
          if (error) {
            errors.submit = errorMessage;
            AnalyticsService.log('enroll_screening_error');

            return errors;
          } else {
            AnalyticsService.log('register_state_country');
            return redirect('/enroll/enter-phone');
          }
        }
      );
    }
  },
});
