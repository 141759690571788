export default {
  content: [
    './src/**/*.{js,jsx,ts,tsx}',
    './index.html',
    'node_modules/@digitalmint/web-components/lib/esm/index.js',
  ],
  theme: {
    screens: {
      sm: { min: '375px', max: '600px' },
      md: '768px',
      lg: '1024px',
      xl: '1440px',
    },
    colors: {
      transparent: 'transparent',
      black: '#000000',
      white: '#ffffff',

      alpha: {
        black: {
          5: 'rgba(0, 0, 0, 0.05)',
          10: 'rgba(0, 0, 0, 0.10)',
          15: 'rgba(0, 0, 0, 0.15)',
          20: 'rgba(0, 0, 0, 0.20)',
          25: 'rgba(0, 0, 0, 0.25)',
          30: 'rgba(0, 0, 0, 0.30)',
          40: 'rgba(0, 0, 0, 0.40)',
          50: 'rgba(0, 0, 0, 0.50)',
          60: 'rgba(0, 0, 0, 0.60)',
          70: 'rgba(0, 0, 0, 0.70)',
          75: 'rgba(0, 0, 0, 0.75)',
          80: 'rgba(0, 0, 0, 0.80)',
          90: 'rgba(0, 0, 0, 0.90)',
        },

        white: {
          5: 'rgba(255, 255, 255, 0.05)',
          10: 'rgba(255, 255, 255, 0.10)',
          15: 'rgba(255, 255, 255, 0.15)',
          20: 'rgba(255, 255, 255, 0.20)',
          25: 'rgba(255, 255, 255, 0.25)',
          30: 'rgba(255, 255, 255, 0.30)',
          40: 'rgba(255, 255, 255, 0.40)',
          50: 'rgba(255, 255, 255, 0.50)',
          60: 'rgba(255, 255, 255, 0.60)',
          70: 'rgba(255, 255, 255, 0.70)',
          75: 'rgba(255, 255, 255, 0.75)',
          80: 'rgba(255, 255, 255, 0.80)',
          90: 'rgba(255, 255, 255, 0.90)',
        },
      },

      gray: {
        300: '#f0f0f0',
        400: '#d8d8d8',
        500: '#c4c4c4',
        600: '#828282',
        700: '#595959',
        800: '#383838',
        900: '#191919',
      },

      nickel: {
        500: '#6e7071',
      },

      blue: {
        400: '#4b9be5',
        500: '#486667',
        600: '#272A39',
        900: '#1e202b',
      },

      green: {
        300: '#10cb80',
        500: '#388640',
      },

      red: {
        500: '#e54b4b',
      },

      yellow: {
        500: '#fecf27',
        600: '#f1a83b',
      },

      system: {
        disabled: '#a6a7a8',
        error: '#e54b4b',
        success: '#388640',
      },
    },
    fontSize: {
      '2xs': '11px',
      xs: '14px',
      sm: '16px',
      md: '24px',
      lg: '32px',
      xl: '40px',
      '2xl': '56px',
    },
    fontFamily: {
      sans: ['Lato', 'sans-serif'],
      mono: ['Roboto Mono', 'monospace'],
    },
    spacing: {
      0: '0',
      1: '1px',
      2: '2px',
      4: '4px',
      8: '8px',
      12: '12px',
      16: '16px',
      20: '20px',
      22: '22px',
      24: '24px',
      28: '28px',
      32: '32px',
      36: '36px',
      40: '40px',
      44: '44px',
      48: '48px',
      56: '56px',
      375: '375px',
      1024: '1024px',
      buttonMd: '300px',
      buttonLg: '300px',
      inputMd: '532px',
      inputLg: '532px',
    },
  },
  plugins: [],
};
