import Data from '../Class';
import LocationData from './Location';
import ProductData from './Product';
import QuoteData from './Quote';

export default class ReceiptData extends Data {
  properties() {
    return {
      BaseQuantity: { type: 'number' },
      BaseRefPrice: { type: 'string' },
      Collected: { type: 'string' },
      CreatedAt: { type: 'string' },
      Markup: { type: 'string' },
      Name: { type: 'string' },
      SalePrice: { type: 'string' },
      State: { type: 'number' },
      TXID: { type: 'string' },
      WithdrawalAddress: { type: 'string' },
    };
  }

  hasOne() {
    return {
      Location: LocationData,
      Product: ProductData,
      Quote: QuoteData,
    };
  }
}
