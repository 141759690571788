// Links will render in the order below
export default [
  {
    title: 'Products',
    icon: 'products',
    links: [
      {
        icon: 'bill',
        name: 'Purchase History',
        to: '/transactions',
        auth: true,
      },
      {
        icon: 'wallet',
        name: 'Default Wallet Address',
        to: '/update/wallet/verify-phone',
        auth: true,
      },
      {
        icon: 'location',
        name: 'Find Location',
        href: 'https://www.digitalmint.io/bitcoin-atm-locations/',
      },
    ],
  },
  {
    title: 'Help',
    icon: 'info',
    links: [
      {
        icon: 'faq',
        name: "FAQ's",
        href: 'https://www.digitalmint.io/customer-support/faqs',
      },
      {
        icon: 'mail',
        name: 'Contact Us',
        to: '/contact-us',
      },
    ],
  },
  {
    title: 'Profile',
    icon: 'profile',
    auth: true,
    links: [
      {
        icon: 'contacts',
        name: 'Personal Info',
        auth: true,
        to: '/profile',
      },
      {
        icon: 'arrowBack',
        name: 'Sign Out',
        auth: true,
        logout: true,
        to: '/',
      },
    ],
  },
];
