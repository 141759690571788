import { apiRouteExtension } from 'src/services/Api';
import {
  validateMinLength,
  validateMaxLength,
} from '@digitalmint/utilities/validators';
import { CreatePinView } from 'src/views';
import AnalyticsService from 'src/services/Analytics';

export default apiRouteExtension({
  path: '/update/pin/create',
  element: <CreatePinView />,
  auth: true,
  action: async ({ request, redirect, fetch }) => {
    const formData = await request.formData();
    const PIN = formData.get('PIN');
    const ConfirmPIN = formData.get('ConfirmPIN');
    const errors: any = {};

    if (!PIN) {
      errors.PIN = 'PIN is required.';

      return errors;
    }

    if (!validateMinLength(PIN, 4) || !validateMaxLength(PIN, 6)) {
      errors.PIN = 'PIN must be between 4 and 6 digits.';

      return errors;
    }

    if (!ConfirmPIN) {
      errors.ConfirmPIN = 'Confirm PIN is required.';

      return errors;
    }

    if (PIN !== ConfirmPIN) {
      errors.ConfirmPIN = 'Confirm PIN does not match.';

      return errors;
    }

    return await fetch(
      {
        method: 'post',
        url: '/account',
        body: { PIN },
      },
      ({ error, errorMessage }) => {
        if (error) {
          errors.submit = errorMessage;
          AnalyticsService.log('account_pin_error');

          return errors;
        } else {
          return redirect('/update/pin/success');
        }
      }
    );
  },
});
