import WelcomeView from 'src/views/Welcome';
import { apiRouteExtension } from 'src/services/Api';
import AuthService from 'src/services/Auth';
import { TransactionsPath } from 'src/routes/paths';

export default apiRouteExtension({
  path: '/',
  element: <WelcomeView />,
  loader: async ({ redirect }) => {
    if (AuthService.Profile?.DOB) {
      return redirect(TransactionsPath);
    }
    return false;
  },
});
