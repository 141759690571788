import Popup from '../Popup';

export default function () {
  return (
    <Popup
      title="This is Not a Wallet"
      text={<p className="popup-link">This is Not a Wallet</p>}
      controls="Close"
      testID="wallet-popup"
      icon="info"
      content={() => (
        <section className="flex flex-col items-center">
          <p className="mb-20">
            We are showing the purchases you have made from DigitalMint. Those
            purchases went to the wallet address location in the details of each
            transaction.
          </p>
        </section>
      )}
    />
  );
}
