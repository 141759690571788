import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { cleanUrl } from '@digitalmint/api-service';
import App from './App.tsx';
import './styles/index.css';

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    replaysOnErrorSampleRate: 1.0,
    tracesSampleRate: 1.0,
    tunnel: cleanUrl(
      `${import.meta.env.VITE_API_URL}/${import.meta.env.VITE_API_VERSION}/proxy/sentry`
    ),
  });
}

let container = document.getElementById('root');
if (!container) {
  Sentry.captureException("no root container")
  container = document.body
}
const root = createRoot(container);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
