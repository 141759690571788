import AddWalletRoute from './AddWallet';
import AlreadyEnrolledRoute from './AlreadyEnrolled';
import ConfirmEnrollmentRoute from './ConfirmEnrollment';
import ConfirmWalletRoute from './ConfirmWallet';
import CreatePasswordEnrollmentRoute from './CreatePassword';
import CustomerCreatePinRoute from './CreatePin';
import DefaultWalletRoute from './DefaultWallet';
import EnterEmailRoute from './EnterEmail';
import EnterPhoneRoute from './EnterPhone';
import ErrorRoute from './Error';
import FailRoute from './Fail';
import IDVerificationRoute from './IDVerification';
import ReviewRoute from './Review';
import SelectEnrollmentRoute from './SelectEnrollment';
import SignupRoute from './Signup';
import SignupSuccessRoute from './SignupSuccess';
import StartRoute from './Start';
import SuccessRoute from './Success';
import UnapprovedRoute from './Unapproved';
import VerifyEmailRoute from './VerifyEmail';
import VerifyPhoneRoute from './VerifyPhone';

export default [
  AddWalletRoute,
  AlreadyEnrolledRoute,
  ConfirmEnrollmentRoute,
  ConfirmWalletRoute,
  CreatePasswordEnrollmentRoute,
  CustomerCreatePinRoute,
  DefaultWalletRoute,
  EnterEmailRoute,
  EnterPhoneRoute,
  ErrorRoute,
  FailRoute,
  IDVerificationRoute,
  ReviewRoute,
  SelectEnrollmentRoute,
  SignupRoute,
  SignupSuccessRoute,
  StartRoute,
  SuccessRoute,
  UnapprovedRoute,
  VerifyEmailRoute,
  VerifyPhoneRoute,
];
