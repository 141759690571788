import { apiRouteExtension } from 'src/services/Api';
import { validateEmail } from '@digitalmint/utilities/validators';
import { EnterEmailView } from 'src/views';
import AnalyticsService from 'src/services/Analytics';

export default apiRouteExtension({
  path: '/enroll/email',
  element: <EnterEmailView />,
  action: async ({ request, fetch, redirect }) => {
    const formData = await request.formData();
    const Email = formData.get('Email');
    const ConfirmEmail = formData.get('ConfirmEmail');
    const errors: any = {};

    if (!Email) {
      errors.Email = 'E-mail is required.';

      return errors;
    }

    if (!ConfirmEmail) {
      errors.Email = 'Confirm E-mail is required.';

      return errors;
    }

    if (!validateEmail(Email)) {
      errors.Email = 'E-mail is not valid.';

      return errors;
    }

    if (Email !== ConfirmEmail) {
      errors.Email = 'E-mail does not match.';

      return errors;
    }

    return await fetch(
      {
        method: 'post',
        url: '/email',
        body: { Email },
      },
      ({ error, errorMessage }) => {
        if (error) {
          errors.submit = errorMessage;
          AnalyticsService.log('account_email_error');

          return errors;
        } else {
          return redirect('/enroll/verify-email');
        }
      }
    );
  },
});
