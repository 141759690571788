import AppLayout from 'src/layouts/App';
import ErrorBoundary from 'src/layouts/ErrorBoundary';
import AuthRoutes from './auth';
import ContactUsRoute from './ContactUs';
import EnrollRoutes from './enroll';
import ErrorRoute from './Error';
import NotFoundRoute from './NotFound';
import ReceiptRoute from './Receipt';
import SessionExpiredRoute from './SessionExpired';
import SignInRoute from './SignIn';
import UpdateEmailRoutes from './auth/update/email';
import UpdatePasswordRoutes from './auth/update/password';
import UpdatePinRoutes from './auth/update/pin';
import UpdateWalletRoutes from './auth/update/wallet';
import WelcomeRoute from './Welcome';
import * as Sentry from '@sentry/react';

const SentryWrapper = () => (
  <Sentry.ErrorBoundary fallback={ErrorRoute.element}>
    <AppLayout />
  </Sentry.ErrorBoundary>
);

export default [
  {
    path: '/',
    element: <SentryWrapper />,
    errorElement: <ErrorBoundary />,
    children: [
      ...AuthRoutes,
      ...EnrollRoutes,
      ...UpdateEmailRoutes,
      ...UpdatePasswordRoutes,
      ...UpdatePinRoutes,
      ...UpdateWalletRoutes,
      ContactUsRoute,
      NotFoundRoute,
      ReceiptRoute,
      SessionExpiredRoute,
      SignInRoute,
      WelcomeRoute,
    ],
  },
];
