import { useState, useMemo } from 'react';
import { useActionData, useNavigation } from 'react-router-dom';
import { WorkflowLayout } from 'src/layouts';
import { Button, Dropdown } from '@digitalmint/web-components';
import { STATE_OPTIONS, COUNTRY_OPTIONS } from 'src/types';
import WarnIcon from 'src/services/Notifications/components/icons/warn.svg';
import AnalyticsService from 'src/services/Analytics';

export default function () {
  const [Country, setCountry] = useState('');
  const [State, setState] = useState('');
  const errors: any = useActionData();
  const isLoading = useNavigation().state === 'submitting';

  const isDisabled = useMemo(() => {
    if (Country === 'US' && State?.length > 0) {
      return false;
    } else {
      return true;
    }
  }, [Country, State]);

  const showMessage = useMemo(() => {
    if (Country === 'Non-US') {
      return true;
    } else {
      return false;
    }
  }, [Country]);

  const showState = useMemo(() => {
    if (Country === 'US') {
      return true;
    } else {
      return false;
    }
  }, [Country]);

  return (
    <WorkflowLayout
      title="Enter Your Residence to Get Started"
      icon="map"
      errors={errors}
      controls={
        <>
          <p className="text-nickel-500 text-center mt-8 m-16">
            By selecting "Continue" you agree to the{' '}
            <a
              href="https://www.digitalmint.io/terms-of-service/"
              target="_blank"
              rel="noopener"
              data-testid="privacy"
              onClick={() => AnalyticsService.log('view_tos')}
            >
              DigitalMint Privacy Policy
            </a>
          </p>
          <Button
            className="mt-16"
            disabled={isDisabled}
            isLoading={isLoading}
            submit={true}
            text="Continue"
            testID="submit"
          />
        </>
      }
    >
      <Dropdown
        className="self-center mb-20"
        label="Country of Residence"
        name="Country"
        required={true}
        testID="Country"
        defaultOption="Select Country"
        options={COUNTRY_OPTIONS}
        onChange={({ target }) => {
          setCountry(target.value);
        }}
      />

      {showState && (
        <Dropdown
          className="self-center mb-20"
          label="State of Residence"
          name="State"
          required={true}
          testID="State"
          defaultOption="Select State"
          options={STATE_OPTIONS}
          onChange={({ target }) => {
            setState(target.value);
          }}
        />
      )}

      {showMessage && (
        <div
          className="notification-item warn mt-8"
          role="alert"
          aria-live="assertive"
          data-testid="country-alert"
        >
          <img
            className="notification-item-icon"
            src={WarnIcon}
            alt="Warning"
          />
          <div className="notification-item-content">
            <p>
              Due to local regulations, buying or selling bitcoin with
              DigitalMint is currently not available outside of the US.
            </p>
          </div>
        </div>
      )}
    </WorkflowLayout>
  );
}
