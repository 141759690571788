import ApiService from './Service';
import checkExpiration from './checkExpiration';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function ({
  headers = {},
  method = 'get',
  onError,
  onSuccess,
  url,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleFetch = async (body?: any, params?: any) => {
    setIsLoading(true);

    const response = await ApiService.fetch({
      body,
      headers,
      method,
      params,
      url,
    });

    setIsLoading(false);

    if (response.status === 401) {
      if (checkExpiration(response.data)) {
        navigate('/session-expired');
      } else {
        navigate('/signin');
      }
    } else if (response.success && onSuccess) {
      onSuccess(response.data, response.status);
    } else if (response.error && onError) {
      onError(response.errorMessage, response.status);
    }

    return response;
  };

  const result: [(body?: any, params?: any) => Promise<any>, boolean] = [handleFetch, isLoading]
  return result
}
