import { useState, useEffect } from 'react';
import { Link, useLoaderData, useSubmit } from 'react-router-dom';
import { usePlaidLink } from 'react-plaid-link';
import WorkflowLayout, { WorkflowLayoutProps } from 'src/layouts/Workflow';
import { Button } from '@digitalmint/web-components';
import { PLAID_EVENT } from 'src/types';
import AnalyticsService from 'src/services/Analytics';

export default function (props: WorkflowLayoutProps) {
  const [title, setTitle] = useState('ID verification loading...');
  const [isIncomplete, setIsIncomplete] = useState(false);
  const [IdentityVerificationID, setIdentityVerificationID] =
    useState('');
  const [SessionStatus, setSessionStatus] = useState('');
  const submit = useSubmit();
  const { EnrollmentType, LinkToken }: any = useLoaderData();

  const { error, open, ready } = usePlaidLink({
    token: LinkToken,

    onLoad: () => {
      setTitle('ID Verification In Progress');
    },

    onSuccess: (/* publicToken, metadata */) => {
      setTitle('ID Verification Complete');
    },

    onExit: error => {
      if (error) {
        setSessionStatus('error');
      } else if (!SessionStatus) {
        setIsIncomplete(true);
      } else {
        setTitle('ID Verification Exiting');
      }
    },

    onEvent: (eventName, metadata) => {
      let event = '';

      // Session SessionStatuses would be captured at the end of the session
      const SessionStatus: string =
        PLAID_EVENT.ID_VERIFICATION_SESSION_STATUS[eventName];

      if (SessionStatus) {
        setIdentityVerificationID(metadata.link_session_id);
        setSessionStatus(SessionStatus);
      }

      if (eventName) {
        event += `_${eventName.toLowerCase()}`;
      }

      if (metadata.view_name) {
        event += `_${metadata.view_name.toLowerCase()}`;
      }

      AnalyticsService.log(event);
    },
  });

  useEffect(() => {
    if (ready) {
      AnalyticsService.log('tpidv_open');
      open();
    }
  }, [ready]);

  useEffect(() => {
    if (error) {
      AnalyticsService.log('tpidv_error');
      setSessionStatus('error');
    }
  }, [error]);

  useEffect(() => {
    if (SessionStatus) {
      submit(
        {
          EnrollmentType,
          IdentityVerificationID,
          SessionStatus,
        },
        {
          method: 'post',
          action: '/enroll/id-verification',
        }
      );
    }
  }, [SessionStatus]);

  return (
    <WorkflowLayout
      subtitle={props.subtitle}
      title={title}
      controls={
        isIncomplete ? (
          <>
            <Button
              className="mb-12"
              onClick={() => {
                open();
                setIsIncomplete(false);
              }}
            >
              Continue
            </Button>
            <Link to="/" className="dm-button secondary">
              Exit
            </Link>
          </>
        ) : (
          <></>
        )
      }
    >
      {isIncomplete && (
        <>
          <p className="mb-20">You exited enrollment without finishing.</p>
        </>
      )}
    </WorkflowLayout>
  );
}
