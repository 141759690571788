import { useLoaderData } from 'react-router-dom';
import { WorkflowLayout } from 'src/layouts';
import { WorkflowLayoutProps } from 'src/layouts/Workflow';
import { Icon } from '@digitalmint/web-components';
import { Color } from 'src/utils';
import { TransactionItem } from 'src/components';
import { NotWalletPopup } from 'src/components/popups';

export default function (props: WorkflowLayoutProps) {
  const { Transactions }: any = useLoaderData();

  return (
    <WorkflowLayout title="" subtitle={props.subtitle}>
      {/*<div className="flex w-full justify-between items-center">
        <p className="text-blue-500 m-0">Total Amount Purchased</p>
        <p className="text-green-500 text-md m-0">{TotalAmountPurchased} BTC</p>
      </div>*/}

      <div className="w-full mb-48">
        {Transactions && Transactions.length > 0 ? (
          <>
            <div className="text-center my-24">
              <NotWalletPopup />
            </div>
            <h5 className="place-self-start font-bold text-gray-600 mb-12">
              Purchase History
            </h5>
            <ul className="min-h-[50vh] max-h-[50vh] overflow-y-auto">
              {Transactions?.map(tx => {
                return <TransactionItem key={tx.Hash} {...tx} />;
              })}
            </ul>
          </>
        ) : (
          <p>No Transactions Found</p>
        )}
      </div>
      <a
        href="https://www.digitalmint.io/bitcoin-atm-locations/"
        target="_blank"
        data-testid="location-link"
        className="justify-self-end dm-button flex flex-row justify-center w-full items-center rounded-md py-4 px-16 font-bold self-center text-sm h-56 box-border text-white bg-green-500 cursor-pointer"
      >
        Find Location
        <Icon
          className="ml-8 mt-4"
          color={Color.white}
          icon="leave"
          height="16"
          width="16"
        />
      </a>
    </WorkflowLayout>
  );
}
