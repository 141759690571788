import { useContext } from 'react';
import NotificationsContext from './Context';

export default function () {
  const { alert, info, success, warn }: any = useContext(NotificationsContext);

  return {
    alert,
    info,
    success,
    warn,
  };
}
