import { Link } from 'react-router-dom';
import { WorkflowLayout } from 'src/layouts';
import { TransactionsPath, ProfilePath } from 'src/routes/paths';

export default function () {
  return (
    <WorkflowLayout
      title="Your password has been successfully updated!"
      icon="check"
      controls={
        <>
          <Link className="dm-button primary" to={TransactionsPath}>
            Purchase History
          </Link>
          <Link to={ProfilePath} className="dm-button secondary mt-16">
            Profile
          </Link>
        </>
      }
    />
  );
}
