import PropTypes from 'prop-types';

export default function Toggle({ onClick, leftText, rightText }) {
  return (
    <div className="flex flex-row justify-items-center">
      <p className="mt-8" data-testid="leftText">
        {leftText}
      </p>
      <label className="switch">
        <input type="checkbox" data-testid="xToggle" onClick={onClick} />
        <span className="slider"></span>
      </label>
      <p className="mt-8" data-testid="rightText">
        {rightText}
      </p>
    </div>
  );
}

Toggle.propTypes = {
  onClick: PropTypes.func,
  leftText: PropTypes.string,
  rightText: PropTypes.string,
};
