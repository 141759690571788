import { useMemo } from 'react';
import { CONTACT } from 'src/types';
import { Link, useLoaderData } from 'react-router-dom';
import { DataFields, Icon } from '@digitalmint/web-components';
import { CopyToClipboard, TransactionState } from 'src/components';
import { UserLayout } from 'src/layouts';
import {
  formatCoin,
  formatCurrency,
  formatPhoneNumber,
} from '@digitalmint/utilities/formatters';
import { calculateFee } from '@digitalmint/utilities/calculators';
import { Color, truncateString } from 'src/utils';
import moment from 'moment-timezone';

export default function () {
  const { Receipt }: any = useLoaderData();

  const Symbol = useMemo(() => {
    return Receipt.Product?.Symbol.substring(0, 3) || '';
  }, [Receipt.Product?.Symbol]);

  const BaseQuantity = useMemo(() => {
    return formatCoin(Receipt.BaseQuantity);
  }, [Receipt.BaseQuantity]);

  const SalePrice = useMemo(() => {
    return formatCurrency(Receipt.SalePrice, { show: true });
  }, [Receipt.SalePrice]);

  const Collected = useMemo(() => {
    return formatCurrency(Receipt.Collected, { show: true });
  }, [Receipt.Collected]);

  const date = useMemo(() => {
    const daylight = moment().isDST() ? 'CDT' : 'CST';

    let formattedDate = moment(Receipt.CreatedAt).format(
      'MMM DD, YYYY | hh:mm:ss  A '
    );

    formattedDate = formattedDate + daylight;

    return formattedDate;
  }, [Receipt.CreatedAt]);

  const truncatedTXID = useMemo(() => {
    return truncateString(Receipt.TXID);
  }, [Receipt.TXID]);

  const truncatedAddress = useMemo(() => {
    return truncateString(Receipt.WithdrawalAddress);
  }, [Receipt.WithdrawalAddress]);

  const MarkupPrice = useMemo(() => {
    const markup = calculateFee({
      BaseQuantity: Receipt.BaseQuantity,
      BaseRefPrice: Receipt.BaseRefPrice,
      Collected: Receipt.Collected,
    });

    const base = formatCurrency(markup.base, {
      show: true,
    });

    const fee = formatCurrency(markup.fee, {
      show: true,
    });

    const received = formatCurrency(markup.received, { show: true });

    return {
      base,
      fee,
      received,
    };
  }, [Receipt.BaseQuantity, Receipt.BaseRefPrice, Receipt.Collected]);

  const fields = useMemo(() => {
    const fieldMap = [
      {
        label: (
          <span className="flex flex-row items-center">
            <Icon className="mr-8" icon="hashMark" height="20" width="20" />
            TXID
          </span>
        ),
        value: Receipt.TXID ? (
          <>&mdash;</>
        ) : (
          <CopyToClipboard
            className="text-green-500"
            display={truncatedTXID}
            value={Receipt.TXID}
          />
        ),
      },
      {
        label: (
          <span className="flex flex-row items-center">
            <Icon className="mr-8" icon="wallet" height="20" width="20" />
            Wallet
          </span>
        ),
        value: (
          <CopyToClipboard
            className="text-green-500"
            display={truncatedAddress}
            value={Receipt.WithdrawalAddress}
          />
        ),
      },
      {
        label: (
          <span className="flex flex-row items-center">
            <Icon className="mr-8" icon="calendar" height="20" width="20" />
            Date
          </span>
        ),
        value: date,
      },
      {
        // TODO: Show listed service Venue for base price when available in parentheses
        label: (
          <span className="flex flex-row items-center">
            <Icon
              className="mr-8"
              icon="currencyExchange"
              height="20"
              width="20"
            />
            {Receipt.Quote?.Source ? (
              <>Base Price ({Receipt.Quote.Source})</>
            ) : (
              <>Base Price</>
            )}
          </span>
        ),
        value: MarkupPrice.base,
      },
      {
        label: (
          <span className="flex flex-row items-center">
            <Icon
              className="mr-8"
              icon="currencyExchange"
              height="20"
              width="20"
            />
            Exchange Rate
          </span>
        ),
        value: SalePrice,
      },
      {
        label: (
          <span className="flex flex-row items-center">
            <Icon className="mr-8" icon="digitalMint" height="20" width="20" />
            DigitalMint Markup
          </span>
        ),
        value: MarkupPrice.fee,
      },
      {
        label: (
          <span className="flex flex-row is-vcentered">
            <Icon
              className="mr-8"
              icon="receive"
              height="20"
              width="20"
              color={Color.red[500]}
            />
            Collected
          </span>
        ),
        value: Collected,
      },
      {
        label: (
          <span className="flex flex-row is-vcentered">
            <Icon
              className="mr-8"
              icon="send"
              height="20"
              width="20"
              color={Color.green[500]}
            />
            {Symbol}
          </span>
        ),

        value: `${BaseQuantity} ${Symbol}`,
      },
      {
        label: (
          <span className="flex flex-row is-vcentered">
            <Icon className="mr-8" icon="location" height="20" width="20" />
            Location
          </span>
        ),
        value: (
          <address className="inline-block text-right not-italic">
            <strong data-testid="LocationName">
              {Receipt.Location?.BusinessName}
            </strong>
            <div data-testid="LocationStreet">
              {Receipt.Location?.Address.Street}{' '}
              {Receipt.Location?.Address.Street2 && (
                <span data-testid="LocationStreet2">
                  {Receipt.Location?.Address.Street2}
                </span>
              )}
            </div>
            <div data-testid="LocationCityStateZip">
              {Receipt.Location?.Address.City},{' '}
              {Receipt.Location?.Address.StateCode}{' '}
              {Receipt.Location?.Address.ZIP}
            </div>
          </address>
        ),
      },
    ];

    if (Receipt.Name) {
      fieldMap.unshift({
        label: (
          <span className="flex flex-row items-center">
            <Icon className="mr-8" icon="profile" height="20" width="20" />
            Name
          </span>
        ),
        value: Receipt.Name,
      });
    }

    return fieldMap;
  }, [
    BaseQuantity,
    Collected,
    MarkupPrice,
    Receipt.Location,
    Receipt.Name,
    SalePrice,
    Symbol,
    date,
    truncatedAddress,
    truncatedTXID,
  ]);

  const year = useMemo(() => {
    return moment().format('YYYY');
  }, []);

  return (
    <UserLayout
      header={
        <>
          <h3 className="heading-3 mb-20 text-center">Transaction Receipt</h3>
          <TransactionState State={Receipt.State} />
        </>
      }
    >
      <DataFields direction="horizontal" fields={fields} />

      <footer className="mt-40 flex flex-col lg:flex-row justify-between items-stretch w-full">
        <ul className="flex flex-col justify-between lg:w-1/2">
          <li>
            <a
              className="border-none hover:border-none"
              href="https://www.digitalmint.io/terms-of-service#BasePriceCalculation"
              target="_blank"
              rel="noreferrer"
            >
              How Base Price is Calculated
            </a>
          </li>
          <li>
            <a
              className="border-none hover:border-none"
              href="https://www.digitalmint.io/terms-of-service#LiabilityDelivery"
              target="_blank"
              rel="noreferrer"
            >
              Liability for Nondelivery or Delayed Delivery
            </a>
          </li>
          <li>
            <a
              className="border-none hover:border-none"
              href="https://www.digitalmint.io/terms-of-service#RefundPolicy"
              target="_blank"
              rel="noreferrer"
            >
              Refund Policy
            </a>
          </li>
        </ul>

        <div className="flex flex-col justify-between text-left mt-8 lg:mt-0 lg:text-right lg:w-1/2">
          <ul>
            <li>
              <strong>FINCEN BSA ID 31000233761836</strong>
            </li>
            <li>
              <strong>NMLS #1923135</strong>
            </li>
          </ul>

          <ul className="flex flex-col lg:items-end mb-8">
            <li>
              <Link
                to="/contact-us"
                className="border-none hover:border-none text-green-500"
              >
                Customer Support/Complaints?
              </Link>
            </li>
            <li className="">
              <a
                href={`tel:${CONTACT.PHONE}`}
                className="inline-flex flex-row text-green-500 border-none hover:border-none sm:text-xs items-center justify-end"
              >
                <Icon
                  className="mr-4"
                  color={Color.green[500]}
                  icon="phone"
                  width="16"
                  height="16"
                />
                {formatPhoneNumber(CONTACT.PHONE)}
              </a>
            </li>
          </ul>
          <p className="mb-0 sm:text-2xs">
            Copyright &copy;{year} Red Leaf Chicago, LLC dba DigitalMint.
            <br />
            All rights reserved.
          </p>
        </div>
      </footer>
    </UserLayout>
  );
}
