import Data from '../Class';
import AddressData from './Address';

export default class LocationData extends Data {
  properties() {
    return {
      BusinessName: { type: 'string' },
      PhoneNumber: { type: 'string' },
    };
  }

  hasOne() {
    return {
      Address: AddressData,
    };
  }
}
