import { useState } from 'react';
import { useActionData, useNavigation } from 'react-router-dom';
import { WorkflowLayout } from 'src/layouts';
import { Button, TextInput } from '@digitalmint/web-components';
import PropTypes from 'prop-types';

export default function EnterPhone({ title = 'Enter Your Phone Number' }) {
  const [isDisabled, setIsDisabled] = useState(true);
  const errors: any = useActionData();
  const isLoading = useNavigation().state === 'submitting';

  return (
    <WorkflowLayout
      title={title}
      icon="phone"
      errors={errors}
      controls={
        <Button
          className="mt-16"
          disabled={isDisabled}
          isLoading={isLoading}
          submit={true}
          text="Continue"
          testID="submit"
        />
      }
    >
      <TextInput
        className="self-center mb-20"
        label="Phone Number"
        name="PhoneNumber"
        icon="phone"
        required={true}
        testID="PhoneNumber"
        placeholder="e.g. (234) 121-1212"
        type="tel"
        style={{ appearance: 'textfield' }}
        onChange={({ target }) => {
          setIsDisabled(target.value?.length < 1);
        }}
        error={!!errors?.PhoneNumber}
        errorMessage={errors?.PhoneNumber}
      />
    </WorkflowLayout>
  );
}

EnterPhone.propTypes = {
  title: PropTypes.string,
};
