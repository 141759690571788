import { useMemo } from 'react';
import ICONS from './icons';
import { Icon } from '@digitalmint/web-components';
import { Color, useTimeout } from 'src/utils';
import { ASSERT_TYPE, DISMISSABLES, TIMEOUT_DELAY, TIMEOUTS } from '../types';
import PropTypes from 'prop-types';
import classnames from 'classnames';

export default function NotificationItem({ body, id, onDismiss, type }) {
  const delay = useMemo(() => {
    return TIMEOUTS.includes(type) ? TIMEOUT_DELAY : 0;
  }, [type]);

  useTimeout(() => {
    onDismiss(id);
  }, delay);

  return (
    <div
      aria-live={ASSERT_TYPE[type]}
      role="alert"
      className={classnames('notification-item', type)}
      data-testid={`notification-${type}`}
    >
      <img className="notification-item-icon" src={ICONS[type]} alt={type} />
      <div className="notification-item-content">{body}</div>

      {DISMISSABLES.includes(type) && (
        <button
          className="notification-item-dismiss"
          onClick={onDismiss}
          data-testid={`notification-${type}-dismiss`}
        >
          <Icon color={Color.blue[600]} icon="close" height="8" width="8" />
          <span className="sr-only">Dismiss</span>
        </button>
      )}
    </div>
  );
}

NotificationItem.propTypes = {
  body: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  onDismiss: PropTypes.func.isRequired,
  type: PropTypes.oneOf(['alert', 'warn', 'success', 'info']).isRequired,
};
