import { useState } from 'react';
import { Link, useActionData, useNavigation } from 'react-router-dom';
import { WorkflowLayout } from 'src/layouts';
import {
  Button,
  TextInput,
  SecureTextInput,
} from '@digitalmint/web-components';
import { WorkflowLayoutProps } from 'src/layouts/Workflow';

export default function (props: WorkflowLayoutProps) {
  const [isDisabled, setIsDisabled] = useState(true);
  const errors: any = useActionData();
  const isLoading = useNavigation().state === 'submitting';

  return (
    <WorkflowLayout
      subtitle={props.subtitle}
      title="Sign In To Your DigitalMint Account"
      errors={errors}
      controls={
        <>
          <Button
            disabled={isDisabled}
            isLoading={isLoading}
            submit={true}
            text="Sign In"
            testID="submit"
          />
        </>
      }
    >
      <TextInput
        className="self-center mb-20"
        label="Phone Number"
        name="PhoneNumber"
        required={true}
        maxLength={20}
        testID="PhoneNumber"
        type="tel"
        style={{ appearance: 'textfield' }}
        error={!!errors?.PhoneNumber}
        errorMessage={errors?.PhoneNumber}
      />

      <SecureTextInput
        className="self-center mb-20"
        label="Password"
        name="Password"
        required={true}
        testID="Password"
        style={{ appearance: 'textfield' }}
        onChange={({ target }) => {
          setIsDisabled(target.value?.length < 1);
        }}
        error={!!errors?.Password}
        errorMessage={errors?.Password}
      />

      <Link className="pl-4 pr-4  mt-12 " to="/update/password">
        Don't have a password yet or forgot it?
      </Link>
    </WorkflowLayout>
  );
}
