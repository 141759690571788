import { apiRouteExtension } from 'src/services/Api';
import TransactionData from 'src/services/Data/source/Transaction';
import { TransactionsView } from 'src/views';
import AuthService from 'src/services/Auth';
import {
  SelectEnrollmentPath,
  TransactionsPath,
  ContactUsPath,
} from 'src/routes/paths';

export default apiRouteExtension({
  path: TransactionsPath,
  element: <TransactionsView />,
  auth: true,
  loader: async ({ redirect, fetch, datasource }) => {
    if (!AuthService.Profile?.DOB) {
      return redirect(SelectEnrollmentPath);
    }

    return await fetch(
      {
        method: 'get',
        url: TransactionsPath,
      },
      async ({ data, error }) => {
        if (error) {
          return redirect(ContactUsPath);
        }

        const Transactions = datasource.createList(
          TransactionData,
          data.Transactions
        );

        return {
          Transactions,
          TotalAmountPurchased: data.TotalAmountPurchased,
        };
      }
    );
  },
});
