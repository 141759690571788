import Data from '../Class';
import AddressData from './Address';

export default class ProfileData extends Data {
  properties() {
    return {
      DOB: { type: 'string' },
      Email: { type: 'string' },
      Hash: { type: 'string' },
      FirstName: { type: 'string' },
      LastName: { type: 'string' },
      PhoneNumber: { type: 'string' },
    };
  }

  hasOne() {
    return {
      Address: AddressData,
    };
  }
}
