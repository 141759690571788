import { useMemo } from 'react';
import { Icon } from '@digitalmint/web-components';
import LinkItem from './LinkItem';
import { Color } from 'src/utils';
import AuthService from 'src/services/Auth';
import PropTypes from 'prop-types';
import { useApi } from 'src/services/Api';
import { useNotifications } from 'src/services/Notifications';

export default function ListItem({
  onClose,
  onLogout,
  expanded,
  icon,
  links,
  title,
}) {
  const isAuthenticated = useMemo(() => {
    return AuthService.isAuthenticated;
  }, [AuthService.isAuthenticated]);

  const notify = useNotifications();
  const [handleResendCode] = useApi({
    method: 'post',
    url: '/verify/resend',
    onError(message: string) {
      notify.alert(message);
    },
    onSuccess() {
      notify.success('A verification code has been sent to you by text.');
    },
  });

  return (
    <li className="site-menu-list-item">
      <h4 className="site-menu-list-item-title">
        <Icon
          color={Color.blue[500]}
          className="mr-8 display"
          height="24"
          width="24"
          icon={icon}
        />
        {title}
        <Icon
          color={Color.blue[500]}
          className="ml-8 toggle"
          height="24"
          width="24"
          icon="arrowNext"
        />
      </h4>
      <ul
        className="site-menu-sublist"
        aria-expanded={expanded}
        aria-controls="menu-link"
        aria-label="Expandable navigation menu"
      >
        {links.map(({ auth, href, icon, name, to, logout }, index) => {
          if (auth) {
            if (isAuthenticated) {
              return (
                <LinkItem
                  onClose={async () => {
                    if (logout) {
                      await onLogout();
                    }

                    if (name == 'Default Wallet Address') {
                      console.log('wallet clicked!');
                      await handleResendCode();
                    }
                    onClose();
                  }}
                  href={href}
                  icon={icon}
                  name={name}
                  to={to}
                  key={index}
                />
              );
            } else {
              return false;
            }
          } else {
            return (
              <LinkItem
                onClose={onClose}
                href={href}
                icon={icon}
                name={name}
                to={to}
                key={index}
              />
            );
          }
        })}
      </ul>
    </li>
  );
}

ListItem.propTypes = {
  expanded: PropTypes.bool,
  icon: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      auth: PropTypes.bool,
      href: PropTypes.string,
      icon: PropTypes.string,
      name: PropTypes.string,
      to: PropTypes.string,
    })
  ),
  onClose: PropTypes.func.isRequired,
  onLogout: PropTypes.func,
  title: PropTypes.string,
};
