import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Logo from 'src/images/logo.svg';
import Menu from './Menu';
import BackButton from './BackButton';
import Tailwind from '../../../tailwind.config';

const BACK_BUTTON_WHITELIST = ['enroll'];
const SITE_MENU_BLACKLIST = ['enroll'];

export default function SiteHeader() {
  const [showBackButton, setShowBackButton] = useState(false);
  const [showSiteMenu, setShowSiteMenu] = useState(true);
  const [isWindowMd, setIsWindowMd] = useState(false);
  const location = useLocation();

  const handleResize = () => {
    setIsWindowMd(window.innerWidth <= parseInt(Tailwind.theme.screens.md));
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    if (isWindowMd && window.history.state && window.history.state.idx >= 1) {
      if (
        BACK_BUTTON_WHITELIST.some(path => location.pathname.includes(path))
      ) {
        setShowBackButton(true);
      } else {
        setShowBackButton(false);
      }
    } else {
      setShowBackButton(false);
    }
  }, [location, isWindowMd]);

  useEffect(() => {
    if (SITE_MENU_BLACKLIST.some(path => location.pathname.includes(path))) {
      setShowSiteMenu(false);
    } else {
      setShowSiteMenu(true);
    }
  }, [location]);

  return (
    <header role="banner" className="site-header">
      <div className="site-header-interior">
        {isWindowMd && (
          <div className="site-header-back-button-container">
            {showBackButton && <BackButton />}
          </div>
        )}

        <div className="site-header-logo">
          <Link to="/" className="transition-none border-b-0 hover:border-b-0">
            <img src={Logo} className="site-header-logo-image" />
          </Link>
        </div>

        <div className="site-header-menu-container">
          {showSiteMenu && <Menu />}
        </div>
      </div>
    </header>
  );
}
