import { Icon } from '@digitalmint/web-components';
import { Link } from 'react-router-dom';
import { Color } from 'src/utils';
import PropTypes from 'prop-types';

export default function MenuLinkItem({ onClose, icon, name, href, to }) {
  return (
    <li className="site-menu-sublist-item" aria-describedby="menu-link">
      {to && (
        <Link to={to} onClick={onClose}>
          <Icon
            className="mr-8"
            color={Color.blue[500]}
            height="20"
            width="20"
            icon={icon}
          />

          {name}
        </Link>
      )}

      {href && (
        <a href={href} onClick={onClose} target="_blank" rel="noreferrer">
          <Icon
            className="mr-8"
            color={Color.blue[500]}
            height="20"
            width="20"
            icon={icon}
          />

          {name}
        </a>
      )}
    </li>
  );
}

MenuLinkItem.propTypes = {
  href: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  to: PropTypes.string,
};
