import ApiService from './Service';
import useApi from './useApi';
import RouteExtension from './RouteExtension';

function apiRouteExtension(config) {
  return new RouteExtension(config);
}

export { useApi, apiRouteExtension };

export default ApiService;
