import { useEffect } from 'react';
import { Form } from 'react-router-dom';
import { Icon } from '@digitalmint/web-components';
import { Color } from 'src/utils';
import PropTypes from 'prop-types';
import { useNotifications } from 'src/services/Notifications';

export type WorkflowLayoutProps = {
  children?: React.ReactNode
  controls?: React.ReactNode
  errors?: any
  icon?: string
  method?: "get" | "post" | "put" | "patch" | "delete";
  onSubmit?: () => void
  testID?: string
  subtitle?: string
  title?: string
}
export default function WorkflowLayout({
  children,
  controls,
  errors,
  icon,
  method = 'post',
  onSubmit,
  testID = 'xWorkflowForm',
  subtitle,
  title,
}: WorkflowLayoutProps ) {
  const notify = useNotifications();

  useEffect(() => {
    if (errors?.submit) {
      notify.alert(errors.submit);
    }
  }, [errors]);

  return (
    <>
      {icon && (
        <Icon
          className="self-center mb-20"
          color={Color.green[500]}
          icon={icon}
          width="42"
          height="42"
        />
      )}
      <Form
        method={method}
        className="flex flex-col m-0 grow items-center justify-center w-full"
        data-testid={testID}
        onSubmit={onSubmit}
      >
        <header className="text-center">
          {subtitle && (
            <h2 className="heading-5 uppercase mb-20">{subtitle}</h2>
          )}
          <h1 className="heading-3 mb-20">{title}</h1>
        </header>
        <div className="w-full mx-0 my-auto text-center flex flex-col items-center justify-center">
          {children}
        </div>
        {controls}
      </Form>
    </>
  );
}

WorkflowLayout.propTypes = {
  children: PropTypes.node,
  controls: PropTypes.element,
  errors: PropTypes.object,
  icon: PropTypes.string,
  method: PropTypes.oneOf(['post', 'get', 'put', 'delete', 'patch']),
  onSubmit: PropTypes.func,
  subtitle: PropTypes.string,
  testID: PropTypes.string,
  title: PropTypes.string.isRequired,
};
