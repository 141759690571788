import { Outlet } from 'react-router-dom';
import SiteHeader from './SiteHeader';
import { Notifications } from 'src/services/Notifications';

export default function () {
  return (
    <div className="app-layout">
      <SiteHeader />
      <main className="app-content">
        <Notifications />
        <Outlet />
      </main>
    </div>
  );
}
