import Data from '../Class';

export default class UnderlyingBaseData extends Data {
  properties() {
    return {
      Hash: { type: 'string' },
      Name: { type: 'string' },
      Symbol: { type: 'string' },
    };
  }
}
