import { apiRouteExtension } from 'src/services/Api';
import { EnterPhoneView } from 'src/views';
import AnalyticsService from 'src/services/Analytics';

export default apiRouteExtension({
  path: '/update/password',
  element: <EnterPhoneView title="Enter Your Phone to Get Started" />,
  loader: () => {
    AnalyticsService.log('forgot_password');

    return false;
  },
  action: async ({ request, fetch, redirect }) => {
    const formData = await request.formData();
    const PhoneNumber = formData.get('PhoneNumber');
    const errors: any = {};

    if (!PhoneNumber) {
      errors.PhoneNumber = 'Phone Number is required.';

      return errors;
    }

    return await fetch(
      {
        method: 'post',
        url: '/greet',
        body: { PhoneNumber },
      },
      ({ error, errorMessage }) => {
        if (error) {
          errors.submit = errorMessage;
          AnalyticsService.log('enter_phone_error');

          return errors;
        } else {
          AnalyticsService.log('phone_submission');
          const path = encodeURI(
            `/update/password/verify-phone?p=${PhoneNumber}`
          );

          return redirect(path);
        }
      }
    );
  },
});
