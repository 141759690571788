import { apiRouteExtension } from 'src/services/Api';
import {
  validateNumericString,
  validateEqualLength,
} from '@digitalmint/utilities/validators';
import { VerifyPhoneView } from 'src/views';
import AnalyticsService from 'src/services/Analytics';

export default apiRouteExtension({
  path: '/update/wallet/verify-phone',
  element: <VerifyPhoneView subtitle="Update Default Wallet" />,
  // loader: ({ request, redirect }) => {
  //   const url = new URL(request.url);
  //   const PhoneNumber = url.searchParams.get('p');
  //
  //   if (PhoneNumber) {
  //     return { PhoneNumber };
  //   } else {
  //     return redirect('/update/wallet');
  //   }
  // },
  action: async ({ request, fetch, redirect }) => {
    const formData = await request.formData();
    const ConfirmCode = formData.get('ConfirmCode');
    const errors: any = {};

    if (!ConfirmCode) {
      errors.ConfirmCode = 'Verification Code is required.';

      return errors;
    }

    if (validateNumericString(ConfirmCode)) {
      if (!validateEqualLength(ConfirmCode, 6)) {
        errors.ConfirmCode = 'Verification Code must be 6 digits.';

        return errors;
      }
    } else {
      errors.ConfirmCode = 'Verification Code must be numbers.';

      return errors;
    }

    return await fetch(
      {
        url: '/verify',
        method: 'post',
        body: {
          ConfirmCode,
        },
      },
      ({ error, errorMessage }) => {
        if (error) {
          errors.submit = errorMessage;
          AnalyticsService.log('verify_phone_error');

          return errors;
        } else {
          AnalyticsService.log('sms_code_submission');

          return redirect('/enroll/default-wallet');
        }
      }
    );
  },
});
