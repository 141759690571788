// https://plaid.com/docs/link/web/#link-web-onevent-eventName
export const ID_VERIFICATION_STEP_STATUS = {
  IDENTITY_VERIFICATION_PASS_STEP: 'success',
  IDENTITY_VERIFICATION_FAIL_STEP: 'fail',
  IDENTITY_VERIFICATION_PENDING_REVIEW_STEP: 'review',
};

export const ID_VERIFICATION_SESSION_STATUS = {
  IDENTITY_VERIFICATION_PASS_SESSION: 'success',
  IDENTITY_VERIFICATION_FAIL_SESSION: 'fail',
  IDENTITY_VERIFICATION_PENDING_REVIEW_SESSION: 'review',
};
