import { apiRouteExtension } from 'src/services/Api';
import { EnrollSignupView } from 'src/views';
import { validateEmail } from '@digitalmint/utilities/validators';
import AnalyticsService from 'src/services/Analytics';

export default apiRouteExtension({
  path: '/enroll/signup',
  element: <EnrollSignupView />,
  loader: ({ request }) => {
    const url = new URL(request.url);
    const State = url.searchParams.get('state');
    const Country = url.searchParams.get('country');

    return {
      Country,
      State,
    };
  },
  action: async ({ request, fetch, redirect }) => {
    const formData = await request.formData();
    const Email = formData.get('Email');
    const Country = formData.get('Country');
    const State = formData.get('State');
    const errors: any = {};

    if (!validateEmail(Email)) {
      errors.Email = 'E-mail address is invalid';

      return errors;
    }

    return await fetch(
      {
        method: 'post',
        url: '/email/marketing',
        body: {
          Email,
          Country,
          State,
        },
      },
      ({ error, errorMessage }) => {
        if (error) {
          errors.submit = errorMessage;
          AnalyticsService.log('email_marketing_error');

          return errors;
        } else {
          return redirect('/enroll/signup-success');
        }
      }
    );
  },
});
