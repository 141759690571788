import { WorkflowLayout } from 'src/layouts';
import { CONTACT } from 'src/types';
import { formatPhoneNumber } from '@digitalmint/utilities/formatters';

export default function () {
  return (
    <WorkflowLayout title="Account Not Approved" icon="warning">
      <p className="text-center mb-40">
        For more information on your account status, please contact customer
        support.
      </p>
      <p className="text-center mb-12">
        <strong className="block">Hours:</strong>
        <span dangerouslySetInnerHTML={{ __html: CONTACT.HOURS }} />
      </p>
      <p className="text-center mb-12">
        <strong className="block">Phone:</strong>
        <a className="font-bold text-green-500" href={`tel:${CONTACT.PHONE}`}>
          {formatPhoneNumber(CONTACT.PHONE)}
        </a>
      </p>
      <p className="text-center">
        <strong className="block">Email:</strong>
        <a
          className="font-bold text-green-500"
          href={`mailto:${CONTACT.EMAIL}`}
        >
          {CONTACT.EMAIL}
        </a>
      </p>
    </WorkflowLayout>
  );
}
